import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { lastValueFrom } from 'rxjs';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BookingService, PurpleApiResponseStatus } from 'src/core-modules/sdk';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { PurpleTranslationPipe } from 'src/purple-widgets/custom-pipe/translation.pipe';

@Component({
  selector: 'app-verify-user-email',
  templateUrl: './verify-user-email.component.html',
  styleUrls: ['./verify-user-email.component.scss']
})
export class VerifyUserEmailComponent implements OnInit {

  constructor(private tsvc: AppTranslationService, private bookSvc: BookingService, private route: ActivatedRoute, 
    private mesSvc: NzMessageService, private notSvc: NzNotificationService, private router: Router, private tranPipe: PurpleTranslationPipe) { }

  async ngOnInit() {
    //console.log("Verify user email")
    this.route.queryParams
      .subscribe(async params => {
        const verificationId = params.verificationId;
        //console.log("Verify user email: ", verificationId)
        const res = await lastValueFrom(this.bookSvc.verifyUserEmail(this.tsvc.currentLanguage.value, {
          verificationId: verificationId
        }));

        if (res.status == PurpleApiResponseStatus.Success) {
          this.mesSvc.success(res.message, {
            nzDuration: 5000
          });

          await this.router.navigate([this.tsvc.currentLanguage.value, 'login']);

        } else {
          this.notSvc.error(this.tranPipe.transform('erro_message_default_title','Errore', []), res.message, {nzDuration: 0, nzPlacement: "top"});

          await this.router.navigate([this.tsvc.currentLanguage.value, 'login']);
        }
      });

      await this.router.navigate(['404']);

  }

}
