import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { environment } from 'src/environments/default/environment';
import { PurpleTranslationPipe } from 'src/purple-widgets/custom-pipe/translation.pipe';
import { EditTextModalComponent } from './edit-text-modal/edit-text-modal.component';
import { EditService } from './edit.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { EditTextDrawerComponent } from './edit-text-drawer/edit-text-drawer.component';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[editableText]'
})
export class EditableTextDirective implements OnInit, OnDestroy {

  constructor(private tsvc: AppTranslationService, private elementRef: ElementRef, private modal: NzModalService, private mesSvc: NzMessageService,
    private editSvc: EditService, private tranPipe: PurpleTranslationPipe, private breakpointObserver: BreakpointObserver, private drawerSvc: NzDrawerService) {}
 
  @Input() editType: "basic" | "table" = "basic";
  @Input() tranKey: string | undefined;

  @Input() dataColumnKey: string | undefined;
  @Input() dataId: string | undefined;
  @Input() dataTable: string | undefined;
  @Input() dataTableColumn: string | undefined;

  private touchTimeout: any;
  isMobile: boolean = false;
  subs: Subscription = new Subscription();

  ngOnInit(): void {
    this.subs.add(this.editSvc.editModeOn.subscribe((s: boolean) => {
      if (s) {
        if (this.elementRef.nativeElement && (this.elementRef.nativeElement as HTMLElement).classList) {
          (this.elementRef.nativeElement as HTMLElement).classList.add('edit-text-mode');
        }

      } else {
        if (this.elementRef.nativeElement && (this.elementRef.nativeElement as HTMLElement).classList && (this.elementRef.nativeElement as HTMLElement).classList.contains('edit-text-mode')) {
          (this.elementRef.nativeElement as HTMLElement).classList.remove('edit-text-mode');
        }
      }
    }));

    this.subs.add(this.breakpointObserver
      .observe(['(max-width: 990px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      }));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  editTranslation() {
    if (this.editSvc.editModeOn.value) {
      try {
        var toTran = false;
        var toEditText: string = "";

        if (this.editType == 'basic') {
          if (this.tranKey == undefined) {
            throw new Error(this.tranPipe.transform("edit_text_no_trankey_error", "Specificare chiave ti traduzione nel codice (rivolgersi a purplesoft)"))
          }
          const tran = (this.tsvc.translations as any)[this.tsvc.currentLanguage.value][environment.EDIT_TRAN_AFFIX + this.tranKey];

          if (tran == undefined) {
            const toTranslateValue = this.tsvc.newTranslations.get(environment.EDIT_TRAN_AFFIX+ this.tranKey)
            if (toTranslateValue != undefined) {
              this.mesSvc.warning(this.tranPipe.transform("edit_text_trankey_to_translate", "Valore non ancora inserito da tradurre")), {
                nzDuration: 2000
              };
              toTran = true;
              toEditText = toTranslateValue
            } else {
              throw new Error(this.tranPipe.transform("edit_text_trankey_error", "Non è stata trovata alcuna traduzione per questa chiave (rivolgersi a purplesoft)"));
            }
          } else {
            toEditText = tran
          }
        } else {
          //TODO SVILUPPARE SE TRADUZIONE NON BASE
        }

        if (this.isMobile) {

          this.drawerSvc.create<EditTextDrawerComponent, { value: string }, string>({
            nzTitle: this.tranPipe.transform('purple_edit_text_edit_text', 'Modifica Traduzione', []),
            nzContent: EditTextDrawerComponent,
            nzClosable: true,
            nzHeight: "100%",
            nzWrapClassName: "edit-text-drawer",
            nzContentParams: {
              editType: this.editType,
              tranKey: this.tranKey,
              dataColumnKey: this.dataColumnKey,
              dataId: this.dataId,
              dataTable: this.dataTable,
              dataTableColumn: this.dataTableColumn,
              translationText: toEditText
            },
            nzFooter: undefined,
            nzPlacement: 'bottom'
          });
        } else {
          this.modal.create({
            nzContent: EditTextModalComponent,
            nzComponentParams: {
              editType: this.editType,
              tranKey: this.tranKey,
              dataColumnKey: this.dataColumnKey,
              dataId: this.dataId,
              dataTable: this.dataTable,
              dataTableColumn: this.dataTableColumn,
              translationText: toEditText
            },
            nzTitle: this.tranPipe.transform('purple_edit_text_edit_text', 'Modifica Traduzione', []),
            nzWidth: '90%',
            nzOkText: "Conferma",
            nzClassName: 'ps-edit-text-modal-wrap',
            nzFooter: null,
            nzMaskClosable: false,
            nzCentered: false
          });
        }

      } catch (error: any) {
        this.mesSvc.error(error), {
          nzDuration: 2000
        };
      }

    }
  }

  //@HostListener('click', ['$event'])
  @HostListener('contextmenu', ['$event'])
  private onClick(event: Event) {
    event.preventDefault();
    this.editTranslation();
  }

  @HostListener('touchstart', ['$event'])
  touchstart(event: Event): void {
    this.touchTimeout = setTimeout(() => {
      event.preventDefault();
      this.editTranslation();
    }, 400);
  }

  @HostListener('touchend')
  touchend(): void {
    this.touchEnd();
  }

  @HostListener('touchcancel')
  touchcancel(): void {
    this.touchEnd();
  }

  private touchEnd(): void {
    clearTimeout(this.touchTimeout);
  }

}
