import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { NzContextMenuService, NzDropdownMenuComponent } from 'ng-zorro-antd/dropdown';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { environment } from 'src/environments/default/environment';
import { EditService } from '../editable-text/edit.service';
import { EditImageComponent } from './edit-image/edit-image.component';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[editableImage]',
  exportAs: 'editableImageDirective'
})


export class EditableImageDirective implements AfterViewInit, OnChanges, OnDestroy, OnInit {
  constructor(private elementRef: ElementRef, private mesSvc: NzMessageService, private modal: NzModalService,
    private tsvc: AppTranslationService, private contextMenuSvc: NzContextMenuService, private editSvc: EditService) {
  }

  @Input() modalHeight: string | undefined;
  @Input() contentId: string | undefined;
  @Input() contentTypeId: string | undefined;
  @Input() contentIndex: number = 1;
  @Input() editAlwaysOn: boolean = false;
  @Input() menuTemplate: NzDropdownMenuComponent | undefined;
  @Input() disabled: boolean = false;
  subs:Subscription = new Subscription();
  basePath: string = localStorage.getItem("BasePath")!;

  ngOnInit(): void {
    this.subs.add(this.editSvc.editModeOn.subscribe((s: boolean) => {
      if (!this.editAlwaysOn) {
        if (s) {
          (this.elementRef.nativeElement as HTMLElement).classList.add('edit-image-mode');
        } else {
          (this.elementRef.nativeElement as HTMLElement).classList.remove('edit-image-mode');
        }
      }
    }))
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngAfterViewInit() {
    if (!this.disabled) {
      this.setBackground();
      if (this.editAlwaysOn) {
        this.elementRef.nativeElement.style.cursor = 'pointer';
      }
    }
  }

  async ngOnChanges(_changes: SimpleChanges) {
    if (!this.disabled) {
      this.setBackground();
      if (this.editAlwaysOn) {
        this.elementRef.nativeElement.style.cursor = 'pointer';
      }
    }else{
      const el = this.elementRef.nativeElement;
      el.style.backgroundImage = "none"
    }
  }

  mediaId: string | undefined;

  currentTimestamp: number = 0;

  @HostListener('contextmenu', ['$event'])
  private onRightClick(event: MouseEvent) {
    if (!this.disabled) {
      event.preventDefault();
      if (this.editAlwaysOn) {
        if (this.menuTemplate != undefined) {
          //console.log("Right click")
          if (this.contentId == undefined || this.contentTypeId == undefined) {
            this.checkPathType()
          }

          this.contextMenuSvc.create(event, this.menuTemplate!);

          //console.log("this.contentId: ", this.contentId, " - contentTypeId: ", this.contentTypeId, " - mediaId: ", this.mediaId)
        }

        else {
          if (this.contentId == undefined || this.contentTypeId == undefined) {
            this.checkPathType()
          }

          //console.log("this.contentId: ", this.contentId, " - contentTypeId: ", this.contentTypeId, " - mediaId: ", this.mediaId)

          this.openEditModal();
        }
      }

      if (this.editSvc.editModeOn.value) {
        if (this.menuTemplate != undefined) {
          //console.log("Right click")
          if (this.contentId == undefined || this.contentTypeId == undefined) {
            this.checkPathType()
          }

          this.contextMenuSvc.create(event, this.menuTemplate!);

          //console.log("this.contentId: ", this.contentId, " - contentTypeId: ", this.contentTypeId, " - mediaId: ", this.mediaId)
        }

        else {
          if (this.contentId == undefined || this.contentTypeId == undefined) {
            this.checkPathType()
          }

          //console.log("this.contentId: ", this.contentId, " - contentTypeId: ", this.contentTypeId, " - mediaId: ", this.mediaId)

          this.openEditModal();
        }
      }
    }
  }

  @HostListener('click')
  private onClick() {
    if (!this.disabled) {
      if ((this.menuTemplate == undefined && this.editAlwaysOn) /* || (this.menuTemplate == undefined) */) {
        if (this.contentId == undefined || this.contentTypeId == undefined) {
          this.checkPathType()
        }

        //console.log("this.contentId: ", this.contentId, " - contentTypeId: ", this.contentTypeId, " - mediaId: ", this.mediaId)

        this.openEditModal();
      }
    }
  }

  openEditModal() {
    this.modal.create({
      nzContent: EditImageComponent,
      nzComponentParams: {
        contentId: this.contentId,
        contentTypeId: this.contentTypeId
      },
      nzTitle: "Modifica immagine",
      nzWidth: '90%',
      nzOkText: "Conferma",
      nzClassName: 'ps-edit-image-modal-wrap',
      nzFooter: null,
      nzMaskClosable: false,
      nzCentered: true
    }).afterClose.subscribe((res) => {
      if (res) {
        this.currentTimestamp += 1;
        this.setBackground();
      }
    });
  }

  private checkPathType() {
    const el = this.elementRef.nativeElement;
    var fullPath: string = "";
    if (el.style.backgroundImage !== '') {
      fullPath = el.style.backgroundImage;
    } else if (el.style.background !== '') {
      fullPath = el.style.background;
    } else if (el.src !== '') {
      fullPath = el.src;
    }

    this.parseBackground(fullPath);
  }

  private parseBackground(backString: string | undefined) {
    if (backString != undefined) {
      if (backString.toLowerCase().includes('getmediabyid?')) {
        this.mediaId = backString
          .toLowerCase()
          .split('getmediabyid?mediaid=')[1]
          .split('"')[0];
      } else if (backString.toLowerCase().includes('getmedia?')) {
        var splitArr = backString
          .toLowerCase()
          .split('getmedia?contentid=')[1]
          .split('&');
        this.contentId = splitArr[0];
        this.contentTypeId = splitArr[1]
          .split('contenttypeid=')[1]
          .split('"')[0];
      }
    }
  }

  private setBackground() {
    const el = this.elementRef.nativeElement;
    if (this.contentId != undefined && this.contentTypeId != undefined) {
      const ImgUrl = `${this.basePath}/${this.tsvc.currentLanguage.value}/medias/getmedia?ContentId=${this.contentId}&ContentTypeId=${this.contentTypeId}&ContentIndex=${this.contentIndex}&ts=${this.currentTimestamp}`
      if (el.nodeName.toLowerCase() == "img") {
        el.src = ImgUrl
      } else {
        //console.log("ELSE: ")
        el.style.backgroundPosition = 'center center'
        el.style.backgroundRepeat = 'no-repeat'
        el.style.backgroundImage = 'url(' + ImgUrl + ')'
      }
    }
  }


}
