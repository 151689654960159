import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree
} from '@angular/router';

import { AuthenticationService } from './authentication.service';
import { AppTranslationService } from '../localization/localization.service';
import { environment } from 'src/environments/default/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard {
  constructor(private router: Router, private authenticationService: AuthenticationService, private tsvc: AppTranslationService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      //console.log("Autenticazione guardia: ", this.authenticationService.isLoggedIn)
      if(this.authenticationService.isLoggedIn){
        return true;
      }

      if(environment.COMPANY_ABB.strEq("ke")){
        return this.router.navigate( [this.tsvc.currentLanguage.value, 'login'], {queryParams: route.queryParams})
      }

      return this.router.navigate( [this.tsvc.currentLanguage.value, 'register'], {queryParams: route.queryParams});
  }
}
