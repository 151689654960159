<ng-container *ngIf="isMobile">
  <nz-header
    class="purple-header ps-d-flex ps-align-items-center ps-justify-content-center ps-p-0">
    <div class="purple-header-container ps-w-100 ps-h-100 ps-d-flex ps-align-items-center ps-justify-content-between">
      <img contentId="logo-header" contentTypeId="logo-company" editableImage class="purple-header-logo"
        (click)="navigateToHome()" />

      <div
        class="ps-d-flex purple-header-menu-mobile-buttons-container ps-flex-wrap ps-align-items-center ps-justiy-content-center">
        <i (click)="showQrCodeDrawer()" class="fsp-22-0 {{qrCodeOpen?'active':''}}" nz-icon nzType="qrcode"
          nzTheme="outline"></i>

          <i (click)="showUserMenuDrawer()" nz-icon nzType="user" nzTheme="outline"  class="fsp-22-0"></i>
      </div>
    </div>
  </nz-header>

  <div
    class="ps-d-flex ps-align-items-center ps-justify-content-end purple-header-mobile-hamburger-container {{menuMobileVisible?'visible':''}}">
    <div (click)="hamburgerClick()" class="hamburger-button">
      <div class="hamburger-button-line" [@hamburguerX]="isHamburguer ? 'hamburguer' : 'topX'"></div>
      <div class="hamburger-button-line" [@hamburguerX]="isHamburguer ? 'hamburguer' : 'hide'"></div>
      <div class="hamburger-button-line" [@hamburguerX]="isHamburguer ? 'hamburguer' : 'bottomX'"></div>
    </div>
  </div>

  <nz-drawer nzWrapClassName="purple-mobile-menu-drawer" [nzClosable]="false" [nzVisible]="menuMobileVisible"
    nzPlacement="right" nzWidth="100%" nzHeight="100%">
    <ng-container *nzDrawerContent>
      <div
        class="ps-w-100 ps-h-100 ps-d-flex ps-flex-wrap ps-align-items-center ps-justify-content-center purple-mobile-menu-drawer-container {{mobileMenuClass}}">
        <ul nz-menu nzMode="vertical">
          <li nz-menu-item routerLinkActive="ant-menu-item-selected"
            [routerLink]="[tsvc.currentLanguage.value, 'event', 'private-sales']"
            (click)="mobileNavigate([tsvc.currentLanguage.value, 'event', 'private-sales'])" editableText
            tranKey='menu_events'>
            {{ 'menu_events' | translation: 'Eventi': []}}
          </li>
          <li nz-menu-item routerLinkActive="ant-menu-item-selected"
            [routerLink]="[tsvc.currentLanguage.value, 'event', 'my-reservations']"
            (click)="mobileNavigate([tsvc.currentLanguage.value, 'event', 'my-reservations'])" editableText
            tranKey='menu_my_reservations'>
            {{ 'menu_my_reservations' | translation: 'Prenotazioni': []}}
          </li>
          <li nz-menu-item routerLinkActive="ant-menu-item-selected"
            [routerLink]="[tsvc.currentLanguage.value, 'event', 'contacts']"
            (click)="mobileNavigate([tsvc.currentLanguage.value, 'event', 'contacts'])" editableText
            tranKey='menu_contacts'>
            {{ 'menu_contacts' | translation: 'Contatti': []}}
          </li>
          <!-- <li nz-menu-item (click)="logout(true)" editableText tranKey='menu_logout'>
            {{ 'menu_logout' | translation: 'Logout': []}}
          </li> -->
        </ul>
        <!-- <div
          class="ps-w-100 ps-d-flex ps-flex-wrap ps-align-iterms-center purple-mobile-menu-drawer-languages-container">
          <a (click)="changeLanguage(lan.languageId)" *ngFor="let lan of tsvc.availableLanguages | async"
            class="language-item ps-my-1 ps-mr-2 {{tsvc.currentLanguage.value==lan.languageId?'current-language':''}}">
            {{lan.languageName | slice:0:2}}</a>
        </div> -->
      </div>
    </ng-container>
  </nz-drawer>

</ng-container>



<nz-header *ngIf="!isMobile"
  class="purple-header ps-w-100 ps-d-flex ps-align-items-center ps-justify-content-center ps-p-0">
  <div class="purple-header-container ps-w-100 ps-h-100 ps-d-flex ps-align-items-center ps-justify-content-between">
    <img contentId="logo-header" contentTypeId="logo-company" editableImage class="purple-header-logo"
      (click)="navigateToHome()" />
    <div
      class="ps-d-lg-flex ps-d-none purple-header-menu-buttons-container ps-flex-wrap ps-align-items-center ps-justiy-content-center">
      <ul nz-menu nzMode="horizontal">
        <li class="purple-header-menu-button" nz-menu-item routerLinkActive="ant-menu-item-selected"
          [routerLink]="[tsvc.currentLanguage.value, 'event', 'private-sales']" editableText tranKey='menu_events'>
          {{ 'menu_events' | translation: 'Eventi': []}}
        </li>
        <li class="purple-header-menu-button" nz-menu-item routerLinkActive="ant-menu-item-selected"
          [routerLink]="[tsvc.currentLanguage.value, 'event', 'my-reservations']" editableText
          tranKey='menu_my_reservations'>
          {{ 'menu_my_reservations' | translation: 'Le mie prenotazioni': []}}
        </li>
        <li class="purple-header-menu-button" nz-menu-item routerLinkActive="ant-menu-item-selected"
          [routerLink]="[tsvc.currentLanguage.value, 'event', 'contacts']" editableText tranKey='menu_contacts'>
          {{ 'menu_contacts' | translation: 'Contatti': []}}
        </li>
        <!-- <li class="purple-header-menu-button" nz-menu-item (click)="logout(false)" editableText tranKey='menu_logout'>
            {{ 'menu_logout' | translation: 'Logout': []}}
          </li> -->
      </ul>
      <div class="ps-d-flex ps-align-items-center user-header-container">
        <!-- <i class="purple-header-languages-icon" nz-icon nzType="global" nzTheme="outline"></i>
        <nz-select nzPlaceHolder="Seleziona Lingua" nzBorderless class="purple-header-languages-select"
          [ngModel]="tsvc.currentLanguage.value" (ngModelChange)="tsvc.SwitchLanguage($event)">
          <nz-option *ngFor="let lan of tsvc.availableLanguages | async" [nzValue]="lan.languageId"
            [nzLabel]="lan.languageName! | slice:0:3">
            {{lan.languageName}}

          </nz-option>
        </nz-select> -->

        <button class="user-header-button ps-d-flex ps-align-items-center" nz-popover [nzPopoverContent]="contentTemplate" 
        nzPopoverTrigger="click" nzPopoverPlacement="bottomRight" nzPopoverOverlayClassName="purple-user-menu" [(nzPopoverVisible)]="layoutSvc.isUserMenuOpen">
          <p class="username">{{authSvc.currentUser?.user?.firstName??"NS"}}</p>
          <i nz-icon nzType="user" nzTheme="outline"></i>
        </button>
        
        <ng-template #contentTemplate>
         <!--  <div class="triangle"></div> -->
          <div class="user-menu-container ps-d-flex ps-flex-wrap ps-align-items-start">
            <div class="ps-w-100 ps-d-flex ps-flex-wrap ps-h-100">
              <h2 class="user-firstname ps-w-100">{{authSvc.currentUser?.user?.firstName??"NS"}}</h2>
              <h3 class="user-email ps-w-100">{{authSvc.currentUser?.user?.email??"******@****.***"}}</h3>
              <div class="line"></div>
              <div class="ps-w-100 ps-d-flex">
                <a class="user-menu-button" editableText tranKey='menu_user_my_profile' [routerLink]="['/',tsvc.currentLanguage.value, 'event', 'user-profile']">
                  {{ 'menu_user_my_profile' | translation: 'Il Mio Account': []}}
                </a>
              </div>
              <div class="ps-w-100 ps-d-flex">
                <a class="user-menu-button" editableText tranKey='menu_user_exit' (click)="logout(false)" >
                  {{ 'menu_user_exit' | translation: 'Esci': []}}
                </a>
              </div>
              <nz-select nzPlaceHolder="Seleziona Lingua" class="user-menu-language-select ps-w-100"
                [ngModel]="tsvc.currentLanguage.value" (ngModelChange)="tsvc.switchLanguage($event)">
                <nz-option *ngFor="let lan of tsvc.availableLanguages" [nzValue]="lan.languageId"
                  [nzLabel]="lan.languageName">
                  {{lan.languageName}}

                </nz-option>
              </nz-select>
             
            </div>
          </div>
        </ng-template>

      </div>
    </div>

  </div>
</nz-header>