<div class="register-external-container ps-d-flex ps-flex-wrap">

  <div class="auth-form-mobile-banner ps-d-lg-none ps-d-flex ps-align-items-center" contentId="{{seId}}-authentication"
    contentTypeId="authentication-image-mobile" editableImage>
    <div
      class="auth-form-mobile-banner-opacity-layer ps-w-100 ps-p-0 ps-h-100 ps-d-flex ps-justify-content-start ps-align-items-center">
      <div class="ps-col-6 ps-pl-4 ps-pr-0">
        <h1 *ngIf="titleText!='#NS#'" editableText tranKey='register_light_title_new' class="auth-form-title ps-w-100">
          {{titleText}}</h1>
      </div>
    </div> 
  </div>

  <div
    class="auth-form-left-container ps-d-flex ps-w-100 ps-flex-wrap ps-col-12 ps-col-lg-6 ps-align-items-center ps-justify-content-end">
    <div class="ps-d-flex ps-w-100 ps-flex-wrap">
      <h1 *ngIf="titleText!='#NS#'" editableText tranKey='register_light_title_new'
        class="auth-form-title ps-mb-0 ps-w-100 ps-d-lg-block ps-d-none purple-title">{{titleText}}</h1>
      <h2 editableText tranKey='register_light_subtitle' [innerHtml]="subtitleText | safeHtml"
        class="auth-form-subtitle ps-w-100 ps-d-lg-block ps-d-none purple-subtitle"></h2>
        <p *ngIf="subSubtitleText!='#NS#'" editableText tranKey='register_light_if_not_saywow_account' class="ps-w-100 auth-form-login-with-saywow-account ps-mt-lg-2 ps-mt-0">
          {{subSubtitleText}}</p>
      <div class="ps-col-12 ps-px-0 ps-d-flex ps-align-items-start">


        <form nz-form [formGroup]="validateForm" class="login-form" (ngSubmit)="submitForm()">

          <nz-form-item>
            <nz-form-control [nzErrorTip]="'required_input_error' | translation: 'Campo obbligatorio!': []">
              <nz-input-group nzPrefixIcon="user">
                <input editableText tranKey='register_light_form_firstname' type="text" nz-input formControlName="firstName" id="firstName"
                  [placeholder]="'register_light_form_firstname' | translation: 'Nome': []" />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-control [nzErrorTip]="'required_input_error' | translation: 'Campo obbligatorio!': []">
              <nz-input-group nzPrefixIcon="user">
                <input editableText tranKey='register_light_form_lastname' type="text" nz-input formControlName="lastName" id="lastName"
                  [placeholder]="'register_light_form_lastname' | translation: 'Cognome': []" />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-control [nzErrorTip]="errorTplEmail">
              <nz-input-group nzPrefixIcon="mail">
                <input editableText tranKey='register_light_form_email' type="email" id="email" nz-input formControlName="email"
                  [placeholder]="'register_light_form_email' | translation: 'Email': []" />
              </nz-input-group>
              <ng-template #errorTplEmail let-control>
                <ng-container editableText tranKey='required_input_error' *ngIf="control.hasError('required')">
                  {{ 'required_input_error' | translation: 'Campo obbligatorio!': []}}
                </ng-container>
                <ng-container editableText tranKey='input_error_email_invalid' *ngIf="control.hasError('format')">
                  {{ 'input_error_email_invalid' | translation: 'Formato email non valido':
                  []}}
                </ng-container>
              </ng-template>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-control [nzErrorTip]="errorTplPass">
              <nz-input-group nzPrefixIcon="lock">
                <input editableText tranKey='register_light_form_password' type="password" id="password" nz-input formControlName="password"
                  [placeholder]="'register_light_form_password' | translation: 'Password': []" />
              </nz-input-group>

              <ng-template #errorTplPass let-control>
                <ng-container editableText tranKey='required_input_error' *ngIf="control.hasError('required')">
                  {{ 'required_input_error' | translation: 'Campo obbligatorio!': []}}
                </ng-container>
                <ng-container editableText tranKey='input_error_password_incosistent1' *ngIf="control.hasError('format')">
                  {{ 'input_error_password_incosistent1' 
                  | translation: 'La password deve contenere almeno 8 caratteri, una lettera maiuscola e un carattere speciale (,;.!@#$%^&*)': []}}
                </ng-container>
              </ng-template>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-control [nzErrorTip]="errorTpl">
              <nz-input-group nzPrefixIcon="lock">
                <input editableText tranKey='register_light_form_confirm_password' [placeholder]="'register_light_form_confirm_password' | translation: 'Conferma Password': []" nz-input
                  type="password" nz-input formControlName="checkPassword" id="checkPassword" />
              </nz-input-group>

              <ng-template #errorTpl let-control>
                <ng-container editableText tranKey='input_error_confirm_password_empty' *ngIf="control.hasError('required')">
                  {{ 'input_error_confirm_password_empty' | translation: 'Conferma la password': []}}
                </ng-container>
                <ng-container editableText tranKey='input_error_confirm_password_incosistent' *ngIf="control.hasError('confirm')">
                  {{ 'input_error_confirm_password_incosistent' | translation: 'Le password inserite non corrispondono':
                  []}}
                </ng-container>
              </ng-template>
            </nz-form-control>
          </nz-form-item>

          <ng-container *ngFor="let policy of policies">
            <container-element [ngSwitch]="policy.policyTypeId">

              <ng-container *ngSwitchCase="'defualt'">
                <nz-form-item class="register-area">
                  <nz-form-control [nzErrorTip]="'form_policy_accept_required' | translation: 'Accettazione obbligatoria!': []">
                    <label nz-checkbox [formControlName]="policy.policyId">
                    </label>
                    <p class="ps-mm-0 ps-pb-0 ps-pl-3">
                      {{policy.policyTitle}}
                    </p>
                    <span *ngIf="policy.isMandatoryPolicy">
                      <sup>*</sup>
                    </span>
                  </nz-form-control>
                </nz-form-item>
              </ng-container>


              <ng-container *ngSwitchCase="'iubenda'">
                <nz-form-item class="register-area">
                  <nz-form-control [nzErrorTip]="'form_policy_accept_required' | translation: 'Accettazione obbligatoria!': []">
                    <label nz-checkbox [formControlName]="policy.policyId">
                    </label>
                    <span editableText class="ps-mm-0 ps-pb-0 ps-pl-3">
                      {{ 'accept' | translation: 'Accetto l\'': []}}<a [href]='policy.policyUrl' class='iubenda iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe'>{{policy.policyTitle}}</a>
                    </span>
                    <span *ngIf="policy.isMandatoryPolicy">
                      <sup>*</sup>
                    </span>
                  </nz-form-control>
                </nz-form-item>
              </ng-container>

              <ng-container *ngSwitchCase="'text'">
                <nz-form-item class="register-area">
                  <nz-form-control [nzErrorTip]="'form_policy_accept_required' | translation: 'Accettazione obbligatoria!': []">
                    <label nz-checkbox [formControlName]="policy.policyId">
                    </label>
                    <p class="ps-mm-0 ps-pb-0 ps-pl-3">
                      {{policy.policyText}}
                    </p>
                    <span *ngIf="policy.isMandatoryPolicy">
                      <sup>*</sup>
                    </span>
                  </nz-form-control>
                </nz-form-item>
              </ng-container>

              <ng-container *ngSwitchCase="'media'">
                <nz-form-item class="register-area">
                  <nz-form-control [nzErrorTip]="'form_policy_accept_required' | translation: 'Accettazione obbligatoria!': []">
                    <label nz-checkbox [formControlName]="policy.policyId">
                    </label>
                    <span editableText class="ps-mm-0 ps-pb-0 ps-pl-3">
                      {{ 'accept' | translation: 'Accetto l\'': []}}<a [href]='policySvc.getPolicyImageUrl(policy)' 
                      class='iubenda iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe'>{{policy.policyTitle}}</a>
                    </span>
                    <span *ngIf="policy.isMandatoryPolicy">
                      <sup>*</sup>
                    </span>
                  </nz-form-control>
                </nz-form-item>
              </ng-container>

              <ng-container *ngSwitchCase="'html'">
                <nz-form-item class="register-area">
                  <nz-form-control [nzErrorTip]="'form_policy_accept_required' | translation: 'Accettazione obbligatoria!': []">
                    <label nz-checkbox [formControlName]="policy.policyId">
                    </label>
                    <span class="ps-mm-0 ps-pb-0 ps-pl-3" [innerHtml]="policy.policyHtml??'' | safeHtml">
                    </span>
                    <span *ngIf="policy.isMandatoryPolicy">
                      <sup>*</sup>
                    </span>
                  </nz-form-control>
                </nz-form-item>
              </ng-container>

            </container-element>
          </ng-container>

          <button editableText tranKey='register_light_register_button' nz-button class="submit-button purple-button" nzType="primary">{{ 'register_light_register_button' |
            translation: 'Conferma Password': []}}
          </button>
          
        </form>
      </div>
    </div>
  </div>

  <div
    class="auth-form-right-container ps-col-6 ps-d-none ps-d-lg-flex ps-justify-content-center ps-align-items-end ps-justify-content-start">
    <div class="auth-form-vertical-image" contentId="{{seId}}-authentication" contentTypeId="authentication-image"
      editableImage>
    </div>
  </div>

</div>