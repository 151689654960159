import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { StorageModule } from '../storage/storage.module';
import { AuthenticationGuard } from './authentication.guard';
import { AuthenticationInterceptor } from './authentication.interceptor';
import { AuthenticationService } from './authentication.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StorageModule
  ],
  exports: [],
  providers: [
    AuthenticationGuard,
    AuthenticationInterceptor,
    AuthenticationService
  ]
})
export class SecurityModule { }
