<ng-container *ngIf="isMobile">
  <nz-header class="purple-header ps-w-100 ps-d-flex ps-align-items-center ps-justify-content-center ps-p-0">
    <div class="purple-header-container ps-w-100 ps-h-100 ps-d-flex ps-align-items-center ps-justify-content-between">
      <img contentId="logo-header" contentTypeId="logo-company" editableImage class="purple-header-logo"
        (click)="navigateToHome()" />

        <div
        class="ps-d-flex purple-header-menu-mobile-buttons-container ps-flex-wrap ps-align-items-center ps-justiy-content-center">
        <i (click)="openChangeLanguageDrawer()" nz-icon nzType="global" nzTheme="outline"  class="fsp-22-0"></i>
      </div>
    </div>
  </nz-header>

  <div
    class="ps-d-flex ps-align-items-center ps-justify-content-end purple-header-mobile-hamburger-container  {{menuMobileVisible?'visible':''}}">
    <div (click)="hamburgerClick()" class="hamburger-button">
      <div class="hamburger-button-line" [@hamburguerX]="isHamburguer ? 'hamburguer' : 'topX'"></div>
      <div class="hamburger-button-line" [@hamburguerX]="isHamburguer ? 'hamburguer' : 'hide'"></div>
      <div class="hamburger-button-line" [@hamburguerX]="isHamburguer ? 'hamburguer' : 'bottomX'"></div>
    </div>
  </div>

  <nz-drawer nzWrapClassName="purple-mobile-menu-drawer" [nzClosable]="false" [nzVisible]="menuMobileVisible"
    nzPlacement="right" nzWidth="100%" nzHeight="100%">
    <ng-container *nzDrawerContent>
      <div
        class="ps-w-100 ps-h-100 ps-d-flex ps-flex-wrap ps-align-items-center ps-justify-content-center purple-mobile-menu-drawer-container {{mobileMenuClass}}">
        <ul nz-menu nzMode="vertical">

          <li nz-menu-item routerLinkActive="ant-menu-item-selected"
            [routerLink]="[tsvc.currentLanguage.value, 'login']" class="purple-header-menu-button-mobile"
            (click)="mobileNavigate([tsvc.currentLanguage.value, 'login'])" editableText tranKey='menu_login'>
            {{ 'menu_login' | translation: 'Accedi': []}}
          </li>
          <li *ngIf="!isKering" nz-menu-item routerLinkActive="ant-menu-item-selected"
            [routerLink]="[tsvc.currentLanguage.value, 'register']" class="purple-header-menu-button-mobile"
            (click)="mobileNavigate([tsvc.currentLanguage.value, 'register'])" editableText tranKey='menu_register'>
            {{ 'menu_register' | translation: 'Registrati': []}}
          </li>
          <li *ngIf="showPublicEvent" nz-menu-item routerLinkActive="ant-menu-item-selected"
            [routerLink]="[tsvc.currentLanguage.value, 'private-sales-preview']" class="purple-header-menu-button-mobile"
            (click)="mobileNavigate([tsvc.currentLanguage.value, 'private-sales-preview'])" editableText
            tranKey='menu_events'>
            {{ 'menu_events' | translation: 'Eventi': []}}
          </li>
          <li nz-menu-item routerLinkActive="ant-menu-item-selected"
            [routerLink]="[tsvc.currentLanguage.value, 'contacts']" class="purple-header-menu-button-mobile"
            (click)="mobileNavigate([tsvc.currentLanguage.value, 'contacts'])" editableText tranKey='menu_contacts'>
            {{ 'menu_contacts' | translation: 'Contatti': []}}
          </li>
        </ul>
        <!-- <div
          class="ps-w-100 ps-d-flex ps-flex-wrap ps-align-iterms-center purple-mobile-menu-drawer-languages-container">
          <a (click)="changeLanguage(lan.languageId)" *ngFor="let lan of tsvc.availableLanguages | async"
            class="language-item ps-my-1 ps-mr-2 {{tsvc.currentLanguage.value==lan.languageId?'current-language':''}}">
            {{lan.languageName | slice:0:2}}</a>
        </div> -->
      </div>
    </ng-container>
  </nz-drawer>

</ng-container>



<nz-header *ngIf="!isMobile"
  class="purple-header ps-w-100 ps-d-flex ps-align-items-center ps-justify-content-center ps-p-0 ">
  <div class="purple-header-container ps-w-100 ps-h-100 ps-d-flex ps-align-items-center ps-justify-content-between">
    <img contentId="logo-header" contentTypeId="logo-company" editableImage class="purple-header-logo"
      (click)="navigateToHome()" />
    <div
      class="ps-d-lg-flex ps-d-none purple-header-menu-buttons-container ps-flex-wrap ps-align-items-center ps-justiy-content-center">
      <ul nz-menu nzMode="horizontal">
        <li class="purple-header-menu-button" nz-menu-item routerLinkActive="ant-menu-item-selected"
          [routerLink]="[tsvc.currentLanguage.value, 'login']"
          [queryParams]="seSvc.returnUrl?{returnUrl:seSvc.returnUrl}:{}" editableText tranKey='menu_login'>
          {{ 'menu_login' | translation: 'Accedi': []}}
        </li>
        <li *ngIf="!isKering" class="purple-header-menu-button" nz-menu-item routerLinkActive="ant-menu-item-selected"
          [routerLink]="[tsvc.currentLanguage.value, 'register']"
          [queryParams]="seSvc.returnUrl?{returnUrl:seSvc.returnUrl}:{}" editableText tranKey='menu_register'>
          {{ 'menu_register' | translation: 'Registrati': []}}
        </li>
        <li *ngIf="showPublicEvent" class="purple-header-menu-button" nz-menu-item
          routerLinkActive="ant-menu-item-selected" [routerLink]="[tsvc.currentLanguage.value, 'private-sales-preview']"
          [queryParams]="seSvc.returnUrl?{returnUrl:seSvc.returnUrl}:{}" editableText tranKey='menu_events'>
          {{ 'menu_events' | translation: 'Eventi': []}}
        </li>
        <li class="purple-header-menu-button" nz-menu-item routerLinkActive="ant-menu-item-selected"
          [routerLink]="[tsvc.currentLanguage.value, 'contacts']"
          [queryParams]="seSvc.returnUrl?{returnUrl:seSvc.returnUrl}:{}" editableText tranKey='menu_contacts'>
          {{ 'menu_contacts' | translation: 'Contatti': []}}
        </li>
      </ul>
      <div class="ps-d-flex ps-align-items-center user-header-container">
        <nz-select nzPlaceHolder="Seleziona Lingua" class="user-menu-language-select ps-w-100"
        [ngModel]="tsvc.currentLanguage.value" (ngModelChange)="tsvc.switchLanguage($event)">
        <nz-option *ngFor="let lan of tsvc.availableLanguages" [nzValue]="lan.languageId"
          [nzLabel]="lan.languageName">
          {{lan.languageName}}

        </nz-option>
      </nz-select>
        
        <ng-template #contentTemplate>
         <!--  <div class="triangle"></div> -->
          <div class="user-menu-container ps-d-flex ps-flex-wrap ps-align-items-start">
            <div class="ps-w-100 ps-d-flex ps-flex-wrap ps-h-100">
              <h2 class="user-firstname ps-w-100" editableText tranKey='menu_user_login_title'>{{ 'menu_user_login_title' | translation: 'Benvenuto': []}}</h2>
              <!-- <h3 class="user-email ps-w-100">{{authSvc.currentUser?.user?.email??"******@****.***"}}</h3> -->
              <div class="line"></div>
              <div class="ps-w-100 ps-d-flex">
                <a class="user-menu-button" editableText tranKey='menu_user_login' [routerLink]="['/',tsvc.currentLanguage.value, 'login']">
                  {{ 'menu_user_login' | translation: 'Accedi': []}}
                </a>
              </div>
              <div *ngIf="!isKering" class="ps-w-100 ps-d-flex">
                <a class="user-menu-button" editableText tranKey='menu_user_register' [routerLink]="['/',tsvc.currentLanguage.value, 'register']" >
                  {{ 'menu_user_register' | translation: 'Registrati': []}}
                </a>
              </div>
              <nz-select nzPlaceHolder="Seleziona Lingua" class="user-menu-language-select ps-w-100"
                [ngModel]="tsvc.currentLanguage.value" (ngModelChange)="tsvc.switchLanguage($event)">
                <nz-option *ngFor="let lan of tsvc.availableLanguages" [nzValue]="lan.languageId"
                  [nzLabel]="lan.languageName">
                  {{lan.languageName}}

                </nz-option>
              </nz-select>
             
            </div>
          </div>
        </ng-template>
      </div>
    </div>

  </div>
</nz-header>