import { Component, Input, OnInit } from '@angular/core';
import { editor } from 'monaco-editor';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { PurpleApiResponseStatus } from 'src/core-modules/sdk';
import { StorageService } from 'src/core-modules/storage/storage.service';
import { environment } from 'src/environments/default/environment';
import { PurpleTranslationPipe } from 'src/purple-widgets/custom-pipe/translation.pipe';


@Component({
  selector: 'app-edit-text-modal',
  templateUrl: './edit-text-modal.component.html',
  styleUrls: ['./edit-text-modal.component.scss']
})
export class EditTextModalComponent implements OnInit {

  constructor(private tsvc: AppTranslationService, private mesSvc: NzMessageService, private tranPipe: PurpleTranslationPipe, private storageSvc: StorageService, private modal: NzModalRef) { }
  @Input() editType: "basic" | "table" = "basic";
  @Input() tranKey!: string;

  @Input() dataColumnKey: string | undefined;
  @Input() dataId: string | undefined;
  @Input() dataTable: string | undefined;
  @Input() dataTableColumn: string | undefined;
  @Input() translationText!: string;
  editor?: editor.ICodeEditor | editor.IEditor;
  isLoading: boolean = false;
  showLoader: boolean = true;

  ngOnInit() {
    this.showLoader = true;
  }

  async save(){
    this.isLoading = true;
    this.showLoader = true;
    if(this.editType=="basic"){
      var res = await this.tsvc.AddOrUpdateTranslation(environment.EDIT_TRAN_AFFIX + this.tranKey, this.translationText)

      if (res.status.toLowerCase() == PurpleApiResponseStatus.Success.toLowerCase()) {
        this.mesSvc.success(this.tranPipe.transform("add_or_update_translations_ok_response", "Operazione completata correttamente"), {
          nzDuration: environment.MESSAGE_DURATION
        })
        this.tsvc.newTranslations.clear();
        this.storageSvc.set("TranslationCrc", res.data?.checksum);

        var translations = (res!.data!.languages! as any).reduce(function(map: any, obj: any) {
          map[obj.languageId!] = obj.translations.reduce((m: any, o: any)=>{
            m[o.key!] = o.value
            return m
          });
          return map;
      }, {});

        this.storageSvc.set("Translations", translations);
        this.showLoader = false;
        this.modal.close();
        window.location.reload();
      } else {
        this.isLoading = false;
        this.showLoader = false;
        this.mesSvc.error(this.tranPipe.transform("add_or_update_translations_error_response", "Errore! non è stato possibile effettuare l'operazione richiesta"), {
          nzDuration: environment.MESSAGE_DURATION
        })
      }
    }
  }

  onEditorInit(e: editor.ICodeEditor | editor.IEditor): void {
    //console.log('On editor init');
    this.editor = e;
    setTimeout(() => {
      this.editor!.trigger('', 'editor.action.formatDocument', null)
      this.showLoader = false;
    }, 100);
  }
}
