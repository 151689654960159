import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { Subscription } from 'rxjs';
import { SaleEventService } from 'src/app/sale-event-service.service';
import { hamburgerAnimation } from 'src/core-modules/animations/hamburger-animation';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { PRPVSaleEventFull } from 'src/core-modules/sdk';
import { environment } from 'src/environments/default/environment';
import { PurpleLoaderService } from 'src/purple-widgets/custom-components/purple-loader/purple-loader.service';
import { PurpleTranslationPipe } from 'src/purple-widgets/custom-pipe/translation.pipe';
import { Guid } from 'src/purple-widgets/helpers/guid.extensions';
import { ChangeLanguageDrawerComponent } from '../../drawers/change-language-drawer/change-language-drawer.component';
import { LayoutService } from '../../layout.service';

@Component({
  selector: 'app-public-header',
  templateUrl: './public-header.component.html',
  styleUrls: ['../header-general.scss', './public-header.component.scss'],
  animations: [hamburgerAnimation]
})

export class PublicHeaderComponent implements OnInit, OnDestroy {

  constructor(public loaderSvc: PurpleLoaderService, public tsvc: AppTranslationService, private router: Router, public seSvc: SaleEventService,
    private breakpointObserver: BreakpointObserver, public layoutSvc: LayoutService, private drawerSvc: NzDrawerService, private tranPipe: PurpleTranslationPipe) {
    this.showPublicEvent = environment.SHOW_PUBLIC_EVENT;
  }


  isMobile: boolean = false;
  isKering: boolean = environment.COMPANY_ABB.strEq("ke");
  seId: string = "";
  subs: Subscription = new Subscription();

  ngOnInit(): void {
    this.subs.add(this.breakpointObserver
      .observe(['(max-width: 990px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      }));

    this.subs.add(this.seSvc.currentSaleEvent.subscribe((saleEvent: PRPVSaleEventFull | undefined) => {
      this.seId = saleEvent?.saleEventId ?? Guid.empty();
    }));

  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  isCollapsed = false;
  isHamburguer = true;
  menuMobileVisible = false;
  menuMobileVisibleFake = false;
  mobileMenuClass = 'close';
  showPublicEvent: boolean = true;

  hamburgerClick() {

    if (this.menuMobileVisible) {
      this.mobileMenuClass = 'close'
      setTimeout(() => {
        this.isHamburguer = !this.isHamburguer;
        this.menuMobileVisible = !this.menuMobileVisible;
      }, 300);
    } else {
      this.isHamburguer = !this.isHamburguer;
      this.menuMobileVisible = !this.menuMobileVisible;
      setTimeout(() => {
        this.mobileMenuClass = 'open'
      }, 300);
    }

  }


  async mobileNavigate(url: string[]) {
    this.isHamburguer = true;
    this.menuMobileVisible = false;
    await this.router.navigate(url);
  }

  async navigateToHome() {
    await this.router.navigate([this.tsvc.currentLanguage.value, 'login']);
  }

  /* async changeLanguage(lan: string) {
    await this.tsvc.SwitchLanguage(lan);
    this.mobileMenuClass = 'close'

    this.isHamburguer = !this.isHamburguer;
    this.menuMobileVisible = !this.menuMobileVisible;

  } */


  openChangeLanguageDrawer() {
    this.drawerSvc.create<ChangeLanguageDrawerComponent, { value: string }, boolean>({
      nzTitle: this.tranPipe.transform("drawer_change_language_title", "Cambia lingua"),
      nzContent: ChangeLanguageDrawerComponent,
      nzWrapClassName: "change-language-drawer",
      nzFooter: undefined,
      nzPlacement: 'bottom',
      nzHeight: "130px",
    })
  }

}
