import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BookingService, GmtInfo, PRPVSaleEventFull, PurpleApiResponseStatus } from 'src/core-modules/sdk';

@Injectable({
  providedIn: 'root'
})
export class SaleEventService {
  //refCode: string | undefined;
  returnUrl: string | undefined;
  currentSaleEvent: BehaviorSubject<PRPVSaleEventFull | undefined> = new BehaviorSubject<PRPVSaleEventFull | undefined>(undefined);
  currentSaleEventUtc: BehaviorSubject<GmtInfo | undefined> = new BehaviorSubject<GmtInfo | undefined>(undefined);


  constructor(private bookSvc: BookingService, private tsvc: AppTranslationService) {
    /* this.currentSaleEvent.subscribe(async (se : PRPVSaleEventFull | undefined)=>{
      await this.getSaleEventUtcByTimeZoneId(this.currentSaleEvent.value?.timeZoneName);
    }); */
   }

  async getSaleEventUtcBySaleEventId(saleEventId: string | undefined) {
    if (saleEventId == undefined) {
      this.currentSaleEventUtc.next(undefined);
      return;
    }

    const res = await lastValueFrom(this.bookSvc.getSaleEventUtcBySaleEventId(this.tsvc.currentLanguage.value, {
      saleEventId: saleEventId
    }));

    this.currentSaleEventUtc.next(res.data);
  }

  async getSaleEventUtcByTimeZoneId(timeZoneId: string | undefined) {
    if (timeZoneId == undefined) {
      this.currentSaleEventUtc.next(undefined);
      return;
    }

    const res = await lastValueFrom(this.bookSvc.getSaleEventUtcByTimeZoneId(this.tsvc.currentLanguage.value, {
      timeZoneId: timeZoneId
    }));

    this.currentSaleEventUtc.next(res.data);
  }

  async getSaleEventParameters(params: Params) {
    //this.refCode = params.refCode;
    //console.log("refCode: ", params.refCode);
    this.returnUrl = params.returnUrl;
    //console.log("returnUrl: ", this.returnUrl)

    if (this.returnUrl != undefined) {
      const sec = this.returnUrl.split("/").pop();
      //console.log("sec: ", sec)

      if (sec != undefined) {
        const res = await lastValueFrom(this.bookSvc.getSaleEventBySaleEventCode(this.tsvc.currentLanguage.value, {
          saleEventCode: sec
        }))

        if (res.status!.toLowerCase() == PurpleApiResponseStatus.Success.toLowerCase() && res.data != undefined) {
          this.currentSaleEvent.next(res.data);
          await this.getSaleEventUtcByTimeZoneId(res.data.timeZoneName);
        } else {
          this.currentSaleEvent.next(undefined);
        }
      }

    }
  }
}
