import { Injectable } from '@angular/core';
import { Params, Router, UrlTree } from '@angular/router';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { PurpleLoaderService } from 'src/purple-widgets/custom-components/purple-loader/purple-loader.service';
import { AddOrUpdateTranslationValueResponsePurpleApiResponse } from '../sdk';
import { AddOrUpdateTranslationValuesResponsePurpleApiResponse, Language, PurpleApiResponseStatus, TranslationValue } from '../sdk';
import * as api from '../sdk/api/localization.service';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AppTranslationService {
  private readonly language = "Language";

  constructor(private storageSvc: StorageService, private localizationSvc: api.LocalizationService, private loaderSvc: PurpleLoaderService, private router: Router) {}
  availableLanguages: Language[] = [];
  translations: Map<string,Map<string, string>> | undefined | null;
  currentLanguage: BehaviorSubject<string> = new BehaviorSubject<string>(localStorage.getItem("DefaultLanguage")??"none");
  isInitialized: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLoading: boolean = false;

  newTranslations: Map<string, string> = new Map<string, string>();

  
  async getLanguages(){
    const res = await lastValueFrom(this.localizationSvc.getLanguages(this.currentLanguage.getValue()))
    if(res.status == PurpleApiResponseStatus.Success){
      this.availableLanguages = res.data??[];
    }
  }

  async checkIfCultureExist(cultureId: string, url: string) : Promise<boolean | UrlTree>{

    await this.getLanguages()
    
    const resIdx = this.availableLanguages.findIndex(f=> f.languageId == cultureId);    
    if(resIdx != -1){
      this.storageSvc.set(this.language, cultureId);
      this.currentLanguage.next(cultureId);

      return true
    }else{
      var defCulture = localStorage.getItem("DefaultLanguage");

      const resIdx = this.availableLanguages.findIndex(f=> f.languageId.strEq(defCulture??''));    
      if(resIdx ==-1){
        defCulture = this.availableLanguages[0].languageId
      }

      this.storageSvc.set(this.language, defCulture);
      this.currentLanguage.next(defCulture!);
      const newUrl = url.replace(cultureId, defCulture!);

      return this.router.parseUrl(newUrl);
    }
  }

  async initialize(forceRefresh: boolean = false): Promise<void> {
    if (!forceRefresh && this.isInitialized.value) {
      return;
    }

    this.isLoading = true;
    const lang = this.currentLanguage.value;
    const currentCrc = this.storageSvc.get<string>("TranslationCrc") ?? undefined;
    const check = await lastValueFrom(this.localizationSvc.checkLocalizationUpdates(lang, {
      checksum: currentCrc
    }));

    if (!check.data) {

      const updTran = await lastValueFrom(this.localizationSvc.getAllTranslationValues(lang));
      //Update crc
      this.storageSvc.set("TranslationCrc", updTran.data!.checksum)

      //Update translations
      this.translations = (updTran!.data!.languages! as any).reduce(function(map: any, obj: any) {
        map[obj.languageId!] = obj.translations.reduce((m: any, o: any)=>{
          m[o.key!] = o.value
          return m
        });
        return map;
    }, {});

    this.storageSvc.set("Translations", this.translations)
    } else {
      this.translations = this.storageSvc.get("Translations");
    }
    this.isLoading = false;
    this.isInitialized.next(true);
  }


  async switchLanguage(lang: string) {
    const queryParams: Params = this.router.parseUrl(this.router.url).queryParams;

    var newUrl = this.router.url.replace(this.currentLanguage.value, lang);
    if (newUrl.includes("?")) {
      newUrl = newUrl.split("?")[0]
    }

    this.storageSvc.set(this.language, lang);
    this.currentLanguage.next(lang);
    await this.initialize(true);

    await this.router.navigate([newUrl], {
      replaceUrl: true,
      queryParams: queryParams
    });

    window.location.reload()
  }

  async SyncTranslations():Promise<AddOrUpdateTranslationValuesResponsePurpleApiResponse>{
    var newTran : Array<TranslationValue> = []

    this.newTranslations?.forEach((value, key)=>{
      newTran.push({
        key: key,
        value: value
      })
    })

    var res = await lastValueFrom(this.localizationSvc.addOrUpdateTranslationValues(this.currentLanguage.value,{
      translations:newTran
    }));

    return res;
  }

  async AddOrUpdateTranslation(key: string, value:string):Promise<AddOrUpdateTranslationValueResponsePurpleApiResponse>{

    var res = await lastValueFrom(this.localizationSvc.addOrUpdateTranslationValue(this.currentLanguage.value,{
      key: key,
      value: value
    }));

    return res;
  }


  
  async getTranslationsChecksum():Promise<string>{

    var res = await lastValueFrom(this.localizationSvc.getChecksum(this.currentLanguage.value));

    return res.data??"";
  }

}
