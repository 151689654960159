<div class="request-change-email-external-container ps-w-100 ps-d-flex ps-flex-wrap">

  <div class="auth-form-mobile-banner ps-d-lg-none ps-d-flex ps-align-items-center" contentId="{{seId}}-authentication" contentTypeId="authentication-image-mobile"
    editableImage>
      <div class="auth-form-mobile-banner-opacity-layer ps-w-100 ps-p-0 ps-h-100 ps-d-flex ps-justify-content-start ps-align-items-center">

          <div class="ps-col-6 ps-pl-4 ps-pr-0">
            <h1 class="auth-form-title ps-w-100" editableText tranKey='change_email_title'>{{ 'change_email_title' | translation: 'Cambia Indirizzo Email': []}}</h1>
          </div>

      </div>

    </div>

  <div
    class="auth-form-left-container ps-d-flex ps-w-100 ps-flex-wrap ps-col-12 ps-col-lg-6 ps-align-items-center ps-justify-content-end">
    <div class="ps-d-flex ps-w-100 ps-flex-wrap">
      <h1 class="auth-form-title ps-mb-0 ps-w-100 ps-d-lg-block ps-d-none" editableText tranKey='change_email_title'>{{ 'change_email_title' | translation: 'Cambia Indirizzo Email': []}}</h1>
      <h2 class="auth-form-subtitle purple-subtitle ps-w-100" editableText tranKey='change_email_subtitle'>{{ 'change_email_subtitle' | translation: 'Richiedi il cambio dell\'indirizzo email': []}}</h2>

      <div class="ps-col-12 ps-px-0 ps-d-flex ps-align-items-start">


        <form nz-form [formGroup]="validateForm" class="login-form" (ngSubmit)="submitForm()">

          <nz-form-item>
            <nz-form-control  nzErrorTip="Please input your email!">
              <nz-input-group nzPrefixIcon="mail">
                <input editableText tranKey='change_email_current_email_ph' type="email" nz-input formControlName="email" [placeholder]="'change_email_current_email_ph' | translation: 'Indirizzo email corrente': []" />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>

          <button nz-button class="submit-button ps-mt-4 sw-border-radius" nzType="primary" editableText tranKey='change_email_button'>{{ 'change_email_button' | translation: 'Richiedi cambio email': []}}</button>

        </form>
      </div>
    </div>
  </div>

  <div
    class="auth-form-right-container ps-col-6 ps-d-none ps-d-lg-flex ps-justify-content-center ps-align-items-end ps-justify-content-start">
    <div class="auth-form-vertical-image" contentId="{{seId}}-authentication" contentTypeId="authentication-image"
      editableImage>
    </div>
  </div>

</div>

