<div class="user-menu-container ps-d-flex ps-flex-wrap ps-align-items-start">
  <div class="ps-w-100 ps-d-flex ps-flex-wrap ps-h-100">
    <h2 class="user-firstname ps-w-100">{{authSvc.currentUser?.user?.firstName??"NS"}}</h2>
    <h3 class="user-email ps-w-100">{{authSvc.currentUser?.user?.email??"******@****.***"}}</h3>
    <div class="line"></div>
    <div class="ps-w-100 ps-d-flex">
      <a class="user-menu-button" editableText tranKey='menu_user_my_profile' (click)="navigateToUserProfile()">
        {{ 'menu_user_my_profile' | translation: 'Il Mio Account': []}}
      </a>
    </div>
    <div class="ps-w-100 ps-d-flex">
      <a class="user-menu-button" editableText tranKey='menu_user_exit' (click)="logout(false)" >
        {{ 'menu_user_exit' | translation: 'Esci': []}}
      </a>
    </div>
    <nz-select nzPlaceHolder="Seleziona Lingua" class="user-menu-language-select ps-w-100"
      [ngModel]="tsvc.currentLanguage.value" (ngModelChange)="tsvc.switchLanguage($event)" nzSize="large">
      <nz-option *ngFor="let lan of tsvc.availableLanguages" [nzValue]="lan.languageId"
        [nzLabel]="lan.languageName">
        {{lan.languageName}}

      </nz-option>
    </nz-select>
   
  </div>
</div>
