<div class="reset-password-external-container ps-w-100 ps-d-flex ps-flex-wrap">

  <div class="auth-form-mobile-banner ps-d-lg-none ps-d-flex ps-align-items-center" contentId="{{seId}}-authentication" contentTypeId="authentication-image-mobile"
    editableImage>
      <div class="auth-form-mobile-banner-opacity-layer ps-w-100 ps-p-0 ps-h-100 ps-d-flex ps-justify-content-start ps-align-items-center">

          <div class="ps-col-6 ps-pl-4 ps-pr-0">
            <h1 class="auth-form-title ps-w-100" editableText tranKey='reset_password_title'>{{ 'reset_password_title' | translation: 'Reset Password': []}}</h1>
          </div>

      </div>

    </div>

  <div
    class="auth-form-left-container ps-d-flex ps-w-100 ps-flex-wrap ps-col-12 ps-col-lg-6 ps-align-items-center ps-justify-content-end">
    <div class="ps-d-flex ps-w-100 ps-flex-wrap">
      <h1 class="auth-form-title ps-mb-0 ps-w-100 ps-d-lg-block ps-d-none" editableText tranKey='reset_password_title'>{{ 'reset_password_title' | translation: 'Reset Password': []}}</h1>
      <h2 class="auth-form-subtitle purple-subtitle ps-w-100" editableText tranKey='reset_password_subtitle'>{{ 'reset_password_subtitle' | translation: 'Reimposta Password': []}}</h2>

      <div class="ps-col-12 ps-px-0 ps-d-flex ps-align-items-start">


        <form nz-form [formGroup]="validateForm" class="login-form" (ngSubmit)="submitForm()">

          <nz-form-item>
            <nz-form-control nzErrorTip="Please input your email!">
              <nz-input-group editableText tranKey='change_email_email' nzPrefixIcon="mail">
                <input type="email" [ngModel]="email" nz-input formControlName="email" [placeholder]="'change_email_email' | translation: 'Indirizzo Mail': []" />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-control [nzErrorTip]="errorTplPass">
              <nz-input-group nzPrefixIcon="lock">
                <input editableText tranKey='chenge_email_password' type="password" id="password" nz-input formControlName="password"
                  [placeholder]="'chenge_email_password' | translation: 'Password': []" />
              </nz-input-group>

              <ng-template #errorTplPass let-control>
                <ng-container editableText tranKey='required_input_error' *ngIf="control.hasError('required')">
                  {{ 'required_input_error' | translation: 'Campo obbligatorio!': []}}
                </ng-container>
                <ng-container editableText tranKey='input_error_password_error' *ngIf="control.hasError('format')">
                  {{ 'input_error_password_error' | translation: 'La password deve contenere almeno 8 caratteri, una letter maiuscola e un carattere speciale':
                  []}}
                </ng-container>
              </ng-template>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-control [nzErrorTip]="errorTpl">
              <nz-input-group nzPrefixIcon="lock">
                <input editableText tranKey='register_form_confirm_password' [placeholder]="'register_form_confirm_password' | translation: 'Conferma Password': []" nz-input
                  type="password" nz-input formControlName="checkPassword" id="checkPassword" />
              </nz-input-group>

              <ng-template #errorTpl let-control>
                <ng-container editableText tranKey='input_error_confirm_password_empty' *ngIf="control.hasError('required')">
                  {{ 'input_error_confirm_password_empty' | translation: 'Conferma la password': []}}
                </ng-container>
                <ng-container editableText tranKey='input_error_confirm_password_incosistent' *ngIf="control.hasError('confirm')">
                  {{ 'input_error_confirm_password_incosistent' | translation: 'Le password inserite non corrispondono':
                  []}}
                </ng-container>
              </ng-template>
            </nz-form-control>
          </nz-form-item>

          <button nz-button class="submit-button ps-mt-4 sw-border-radius" nzType="primary" editableText tranKey='reset_password_button'>{{ 'reset_password_button' | translation: 'Reimposta password': []}}</button>

        </form>
      </div>
    </div>
  </div>

  <div
    class="auth-form-right-container ps-col-6 ps-d-none ps-d-lg-flex ps-justify-content-center ps-align-items-end ps-justify-content-start">
    <div class="auth-form-vertical-image" contentId="{{seId}}-authentication" contentTypeId="authentication-image"
      editableImage>
    </div>
  </div>

</div>

