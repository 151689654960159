import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { LayoutService } from 'src/app/layout/layout.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { environment } from 'src/environments/default/environment';
import { PurpleTranslationPipe } from 'src/purple-widgets/custom-pipe/translation.pipe';

@Component({
  selector: 'app-404',
  templateUrl: './404.component.html',
  styleUrls: ['./404.component.scss']
})
export class Error404Component implements OnInit, OnDestroy {

  constructor(private layoutSvc: LayoutService, private router: Router, public tsvc: AppTranslationService, private tranPipe: PurpleTranslationPipe) { }
  subtitleText: string = this.tranPipe.transform("error_404_subtitle", "Torna alla <a [routerLink]=\"['/', tsvc.currentLanguage.value, 'login']\">HomePage</a>")

  ngOnDestroy(): void {
    this.layoutSvc.setFooterVisibility(true);
    this.layoutSvc.setHeaderVisibility(true);
  }

  ngOnInit() {
    this.layoutSvc.setFooterVisibility(false);
    this.layoutSvc.setHeaderVisibility(false);
  }

  options: AnimationOptions = {
    path: '/assets/loaders/'+environment.COMPANY_ABB+'/error-404.json',
  };

  backToHome(){
    this.router.navigate([this.tsvc.currentLanguage.value, 'event', 'private-sales'])
  }
}
