import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { LottieModule,LottieCacheModule } from 'ngx-lottie';
import player from 'lottie-web';
import { PurpleLoaderComponent } from './purple-loader.component';
import { CommonModule } from '@angular/common';
import { PurpleLoaderService } from './purple-loader.service';

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    PurpleLoaderComponent
  ],
  imports: [
    CommonModule,
    LottieModule.forRoot({ player: playerFactory }),
    LottieCacheModule.forRoot()
  ],
  exports: [
    PurpleLoaderComponent
  ]
})
export class PurpleLoaderModule { 

  static forRoot(): ModuleWithProviders<PurpleLoaderModule> {
    return {
      ngModule: PurpleLoaderModule,
      providers: [PurpleLoaderService],
    };
  }
}
