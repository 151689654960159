import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PurpleLoaderService {

  constructor() {
  }


  public reqs: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  addRequest(loaderId: string) {
    var requests: string[] = this.reqs.value;
    requests.push(loaderId)

    this.reqs.next(requests);
  }

  removeRequest(loaderId: string, delay: number | undefined = undefined) {
    var requests: string[] = this.reqs.value;
    var idx = requests.indexOf(loaderId);

    if (idx > -1) {
      requests.splice(idx, 1);
    }

    if(delay != undefined){
      setTimeout(() => {
        this.reqs.next(requests);
      }, delay);
    }else{
      this.reqs.next(requests);
    }
  }

  removeAllRequest(loaderId: string, delay: number | undefined = undefined) {
    var requests: string[] = this.reqs.value;

    let index = 0;
    while (index < requests.length) {
      if (requests[index].strEq(loaderId)) {
        requests.splice(index, 1);
      } else {
        index++;
      }
    }

    if(delay != undefined){
      setTimeout(() => {
        this.reqs.next(requests);
      }, delay);
    }else{
      this.reqs.next(requests);
    }
  }

}
