import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BehaviorSubject, Subscription } from 'rxjs';
import { StorageService } from 'src/core-modules/storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class EditService implements OnDestroy, OnInit {

  constructor(private mesSvc: NzMessageService, private storageSvc: StorageService) {}

  ngOnInit(): void {
    this.editModeSub = this.editModeOn.subscribe((isOn: boolean) => {
      if (isOn) {
        this.mesSvc.warning("Edit mode attiva", { nzDuration: 2000 });
        this.mesSvc.warning("Tasto destro per editare testo (bordo verde) e immagini (bordo rosso) editabili", { nzDuration: 2000 })
      } else {
        this.mesSvc.remove()
      }
    });
    this.checkIfEditModeIsActive();    
  }

  editModeOn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  editModeSub: Subscription = new Subscription();

  ngOnDestroy(): void {
    this.editModeSub.unsubscribe();
  }

  checkIfEditModeIsActive() {
    const editMode = this.storageSvc.get<boolean>("editModeActive") ?? false;
    this.editModeOn.next(editMode);
  }

  toggleEditMode() {
    this.editModeOn.next(!this.editModeOn.value)
    this.storageSvc.set("editModeActive", this.editModeOn.value)
  }

}
