import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StringColorDirective } from './string-color/string-color.directive';
import { EditableImageDirective } from './editable-image/editable-image.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { EditImageComponent } from './editable-image/edit-image/edit-image.component';
import { EditableTextDirective } from './editable-text/editable-text.directive';
import { NzCodeEditorModule } from 'ng-zorro-antd/code-editor';
import { SafeHtmlPipe } from '../custom-pipe/safe-html.pipe';
import { EditTextModalComponent } from './editable-text/edit-text-modal/edit-text-modal.component';
import { PurpleTranslationPipe } from '../custom-pipe/translation.pipe';
import { PurplePipeModule } from '../custom-pipe/purple-pipe.module';
import { EditTextDrawerComponent } from './editable-text/edit-text-drawer/edit-text-drawer.component';

@NgModule({
  declarations: [
    StringColorDirective,
    EditableImageDirective,
    EditableTextDirective,
    EditImageComponent,
    EditTextModalComponent,
    EditTextDrawerComponent
  ],
  imports: [
    CommonModule,
    NzMessageModule,
    NzModalModule,
    NzTabsModule,
    FormsModule,
    ReactiveFormsModule,
    NzUploadModule,
    NzSpinModule,
    NzIconModule,
    NzSkeletonModule,
    NzPaginationModule,
    NzButtonModule,
    NzDropDownModule,
    NzCodeEditorModule,
    PurplePipeModule
  ],
  providers: [SafeHtmlPipe, PurpleTranslationPipe],
  exports: [StringColorDirective, EditableImageDirective, EditableTextDirective]
})
export class PurpleDirectivesModule { }
