import { Component, Input, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef } from 'ng-zorro-antd/modal';
import {
  NzUploadChangeParam,
  NzUploadFile,
  NzUploadXHRArgs,
} from 'ng-zorro-antd/upload';
import { lastValueFrom } from 'rxjs';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { MediasService, PurpleApiResponseStatus } from 'src/core-modules/sdk';
import { environment } from 'src/environments/default/environment';

@Component({
  selector: 'app-edit-image',
  templateUrl: './edit-image.component.html',
  styleUrls: ['./edit-image.component.scss'],
})
export class EditImageComponent implements OnInit {
  constructor(
    public mediaSvc: MediasService,
    private mesSvc: NzMessageService,
    private modal: NzModalRef,
    public tsvc: AppTranslationService
  ) {}
  @Input() contentId: string | undefined;
  @Input() contentTypeId: string | undefined;

  previewImage: string | undefined = '';
  previewVisible = false;
  isLoading: boolean = false;
  isInError: boolean = false;
  fileList: NzUploadFile[] = [];
  imageUrls: string[] | undefined;

  pageSize: number = 20;
  pageNumber: number = 1;
  totalItems: number = 0;

  selectedImg: string | undefined;
  basePathApi: string = localStorage.getItem("BasePath")!;
  loadImgs: string[] = [];

  async ngOnInit() {
    this.refreshImageUrls(this.pageNumber);
  }

  async refreshImageUrls(pageNumber: number) {
    this.pageNumber = pageNumber;

    this.imageUrls = undefined;
    var res = await lastValueFrom(
      this.mediaSvc.getAllIdsImages(this.tsvc.currentLanguage.getValue(), {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      })
    );
    if (res.status == PurpleApiResponseStatus.Success) {
      //this.imageUrls = res.data!.mediasId!.map(m=> `${environment.API_BASE_PATH}/${this.backSvc.currentLanguage.value}/medias/getmediabyid?mediaId=${m}`)
      this.imageUrls = res.data!.mediasId!;
      this.totalItems = res.data!.totPage! * this.pageSize;
    } else {
      this.mesSvc.error(
        'Ops qualcosa è andato storto, download immagini fallito',
        { nzDuration: environment.MESSAGE_DURATION }
      );
      this.imageUrls = [];
      this.totalItems = 0;
    }
  }

  handlePreview = async (file: NzUploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj!);
    }
    this.previewImage = file.url || file.preview;
    this.previewVisible = true;
  };

  uploadMedia = (item: NzUploadXHRArgs) => {
    var blob = item.file as unknown as Blob;
    return this.mediaSvc
    .uploadComplete(
      this.tsvc.currentLanguage.value,
      blob,
      this.contentTypeId!,
      this.contentId!,
      1,
      ''
    ).subscribe((s) => {
        //this.reloadImage();
        item.onSuccess!(s, item.file, item.file);
      });
  };

  uploadStatusChange(info: NzUploadChangeParam): void {
    if (info.file.status === 'uploading') {
      this.isLoading = true;
    } else if (info.file.status === 'done') {
      this.isInError = false;
      this.mesSvc.success('Immagine caricata con successo');
      this.refreshImageUrls(this.pageNumber);
      this.isLoading = false;
    } else if (info.file.status === 'error') {
      this.mesSvc.error("Errore nel caricamento dell'immagine");
      this.isLoading = false;
    }
  }

  selectImg(img: string) {
    this.selectedImg = img;
  }

  async updateMedia() {
    if (this.contentId != undefined && this.contentTypeId != undefined) {
      var res = await lastValueFrom(
        this.mediaSvc.updateMediaId(this.tsvc.currentLanguage.getValue(), {
          contentId: this.contentId,
          contentTypeId: this.contentTypeId,
          newMediaId: this.selectedImg!,
        })
      );
      if (res.status == PurpleApiResponseStatus.Success) {
        this.mesSvc.success('Immagine aggiornata con successo', {
          nzDuration: environment.MESSAGE_DURATION,
        });
        this.modal.close(true);
      } else {
        this.mesSvc.error('Ops qualcosa è andato storto, immagine fallito', {
          nzDuration: environment.MESSAGE_DURATION,
        });
      }
    } else {
      if (this.contentTypeId == undefined) {
        this.mesSvc.error('ContentTypeId è nullo', {
          nzDuration: environment.MESSAGE_DURATION,
        });
      } else if (this.contentId == undefined) {
        this.mesSvc.error('ContentId è nullo', {
          nzDuration: environment.MESSAGE_DURATION,
        });
      } else if (
        this.contentTypeId == undefined &&
        this.contentId == undefined
      ) {
        this.mesSvc.error('ContentId e ContentTypeId sono nulli', {
          nzDuration: environment.MESSAGE_DURATION,
        });
      } else {
        this.mesSvc.error('Errore generico', {
          nzDuration: environment.MESSAGE_DURATION,
        });
      }
    }
  }

  imgUrlIsLoad(imgId: string){
    this.loadImgs.push(imgId)
  }

  isImgLoad(imgId: string): boolean{
    return this.loadImgs.findIndex(f=> f== imgId) == -1;
  }
}

const getBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
