import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { SecurityModule } from 'src/core-modules/security/security.module';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { PurplePipeModule } from 'src/purple-widgets/custom-pipe/purple-pipe.module';
import { PurpleTranslationPipe } from 'src/purple-widgets/custom-pipe/translation.pipe';
import { PurpleDirectivesModule } from 'src/purple-widgets/custom-directives/purple-directives.module';
import { SaleEventService } from '../sale-event-service.service';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { RouterModule } from '@angular/router';
import { PurpleSelectModule } from 'src/purple-widgets/custom-components/purple-select/purple-select.module';
import { RequestChangeEmailComponent } from './request-change-email/request-change-email.component';
import { ChangeEmailComponent } from './change-email/change-email.component';
import { Guid } from 'src/purple-widgets/helpers/guid.extensions';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { VerifyUserEmailComponent } from './verify-user-email/verify-user-email.component';
import { RegisterComponent } from './register/register.component';
import { PrivateRegisterComponent } from './private-register/private-register.component';

@NgModule({
  declarations: [
    ForgotPasswordComponent,
    LoginComponent,
    ResetPasswordComponent,
    RequestChangeEmailComponent,
    ChangeEmailComponent,
    VerifyUserEmailComponent,
    RegisterComponent,
    PrivateRegisterComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NzButtonModule,
    NzCheckboxModule,
    NzFormModule,
    NzInputModule,
    NzSelectModule,
    ReactiveFormsModule,
    SecurityModule,
    NzLayoutModule,
    PurplePipeModule,
    NzDropDownModule,
    PurpleDirectivesModule,
    RouterModule,
    PurpleSelectModule,
    NzIconModule,
    NzNotificationModule 
  ],
  providers:[
    PurpleTranslationPipe,
    SaleEventService,
    Guid
  ]
})
export class AuthenticationModule { }
