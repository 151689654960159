<div
  class="
    main-container
    ps-w-100
    ps-d-flex
    ps-flex-wrap
    ps-justify-content-start
  "
>
  <div style="height: fit-content;"
    class="ps-col-3 ps-d-flex ps-align-items-center ps-justify-content-center"
  >
    <nz-upload
      [nzCustomRequest]="uploadMedia"
      (nzChange)="uploadStatusChange($event)"
      nzListType="picture-card"
      [(nzFileList)]="fileList"
      [nzPreview]="handlePreview"
      [nzShowUploadList]="false"
    >
      <div>
        <i nz-icon [nzType]="isLoading ? 'loading' : 'plus'"></i>
        <div style="margin-top: 8px">Upload</div>
      </div>
    </nz-upload>
  </div>
  <!-- <nz-modal
      [nzVisible]="previewVisible"
      [nzContent]="modalContent"
      [nzFooter]="null"
      (nzOnCancel)="previewVisible = false"
    >
      <ng-template #modalContent>
        <img [src]="previewImage" [ngStyle]="{ width: '100%' }" />
      </ng-template>
    </nz-modal> -->
  <ng-container *ngIf="imageUrls != undefined; else loader">
    <div style="height: fit-content;"
      class="ps-col-3 ps-d-flex ps-align-items-center ps-justify-content-center"
      *ngFor="let imgUrl of imageUrls"
    >
      <img class="preview-img {{imgUrl == selectedImg?'selected':''}}"
      src="{{basePathApi}}/{{tsvc.currentLanguage.value}}/medias/getmediabyid?mediaId={{imgUrl}}" (load)="imgUrlIsLoad(imgUrl)" (click)="selectImg(imgUrl)" />

      <nz-skeleton-element style="z-index: 9999; position: absolute; height: fit-content;" *ngIf="isImgLoad(imgUrl)" nzType="image" [nzActive]="true"></nz-skeleton-element>

    </div>
  </ng-container>
</div>
<div
  class="
    footer-container
    ps-w-100
    ps-d-flex
    ps-flex-wrap
    ps-align-items-center
    ps-justify-content-center
  "
>
  <div *ngIf="imageUrls != undefined">
    <nz-pagination
      [nzPageSize]="pageSize"
      [nzPageIndex]="pageNumber"
      [nzTotal]="totalItems"
      (nzPageIndexChange)="refreshImageUrls($event)"
    ></nz-pagination>
    <button (click)="updateMedia()" class="save-changes-button" nz-button nzType="primary" [disabled]="selectedImg==undefined">
      <i nz-icon nzType="save"></i>
      Salva Modifiche
    </button>
  </div>
</div>

<ng-template #loader>
  <div style="height: fit-content;"
    class="ps-col-3 ps-d-flex ps-align-items-center ps-justify-content-center"
    *ngFor="let i of [].constructor(pageSize)"
  >
    <nz-skeleton-element nzType="image" [nzActive]="true"></nz-skeleton-element>
  </div>
</ng-template>
