import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subscription, lastValueFrom } from 'rxjs';
import { PolicyAndUserAttributesService } from 'src/app/policy-and-user-attributes-service';
import { SaleEventService } from 'src/app/sale-event-service.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { PolicyItem, FeedbackPolicy, BookingService, PRPVSaleEventFull, PurpleApiResponseStatus } from 'src/core-modules/sdk';
import { AuthenticationService } from 'src/core-modules/security/authentication.service';
import { StorageService } from 'src/core-modules/storage/storage.service';
import { environment } from 'src/environments/default/environment';
import { EditService } from 'src/purple-widgets/custom-directives/editable-text/edit.service';
import { PurpleTranslationPipe } from 'src/purple-widgets/custom-pipe/translation.pipe';
import { Guid } from 'src/purple-widgets/helpers/guid.extensions';

@Component({
  selector: 'app-private-register',
  templateUrl: './private-register.component.html',
  styleUrls: ['./private-register.component.scss']
})
export class PrivateRegisterComponent implements OnInit {
  validateForm!: UntypedFormGroup;
  companyName = "";
  seId: string = "";
  subs: Subscription = new Subscription();
  policies: PolicyItem[] = [];
  isMobile: boolean = false;
  registerBookingPolicies: FeedbackPolicy[] = [];
  titleText: string = "";
  subSubtitleText: string = "";

  constructor(private fb: UntypedFormBuilder, private authSvc: AuthenticationService, private bookSvc: BookingService, private router: Router, private editSvc: EditService, private breakpointObserver: BreakpointObserver,
    public tsvc: AppTranslationService, private route: ActivatedRoute, public seSvc: SaleEventService, private tranPipe: PurpleTranslationPipe, private storageSvc: StorageService,
    public policySvc: PolicyAndUserAttributesService, private modal: NzModalService) {
    this.companyName = environment.COMPANY_NAME ?? "PurpleBooking";

    this.titleText = this.tranPipe.transform('register_light_title_new', 'Benvenuto in {0}!', [this.companyName])
    this.subSubtitleText = this.tranPipe.transform('register_light_if_not_saywow_account', 'Imposta la tua password', [])
  }

  subtitleText: string = this.tranPipe.transform("register_light_subtitle", "Imposta la tua password<span class='dot'>.</span>")
  saleEventCode: string = "";
  referralId: string = "";
  registrationId: string = "";
  hasBooking: boolean = false;

  useIubenda = environment.USE_IUBENDA;

  async submitForm(): Promise<void> {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    if (this.validateForm.valid) {
      this.policies.forEach(policy => {
        this.registerBookingPolicies.push({
          policyId: policy.policyId,
          policyCheck: this.validateForm.controls[policy.policyId].value
        })
      });

      const res = await lastValueFrom(this.bookSvc.confirmPrivateRegistration(this.tsvc.currentLanguage.value, {
        clientHost: window.location.hostname,
        email: this.validateForm.controls["email"].value,
        newPassword: this.validateForm.controls["password"].value,
        resetId: this.registrationId,
        firstName: this.validateForm.controls["firstName"].value,
        lastName: this.validateForm.controls["lastName"].value
      }));

      if (res.status.toLocaleLowerCase() == PurpleApiResponseStatus.Success) {
        this.storageSvc.set('Referral', this.referralId, 'session');

        this.authSvc.login(this.validateForm.controls["email"].value, this.validateForm.controls["password"].value, this.tsvc.currentLanguage.value).then(
          async () => {
            await this.router.navigate([this.tsvc.currentLanguage.value, 'event', 'detail', this.saleEventCode], { queryParams: { returnUrl: "/" + this.tsvc.currentLanguage.value + "/event/detail/" + this.saleEventCode } });
          }
        )
      } else {
        this.modal.create({
          nzTitle: this.tranPipe.transform("modal_reset_password_error", "Ops! qualcosa è andato storto"),
          nzContent: res.message,
          nzWidth: '600px',
          nzClassName: 'ps-modal footer-center',
          nzCancelDisabled: true,
          nzFooter: [
            {
              label: this.tranPipe.transform("modal_reset_password_button", "Conferma"),
              type: "primary",
              onClick: async () => {
                await this.router.navigate([this.tsvc.currentLanguage.value, 'login']);
                this.modal.closeAll();
              }
            }
          ]
        });
      }

    }
  }

  updateConfirmValidator(): void {
    /** wait for refresh value */
    Promise.resolve().then(() => this.validateForm.controls.checkPassword.updateValueAndValidity());
  }

  confirmationValidator = (control: UntypedFormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.validateForm.controls.password.value) {
      return { confirm: true, error: true };
    }
    return {};
  };

  validatePassword = (control: UntypedFormControl): { [s: string]: boolean } => {
    const re = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[,;\.!@#\$%\^&\*])(?=.{8,})");
    const res = re.test(String(control.value));
    //console.log("test pass: ", res)
    if (!res) {
      return { format: true, error: true }
    }
    return {}
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  async ngOnInit(): Promise<void> {
    this.subs.add(this.breakpointObserver
      .observe(['(max-width: 650px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      }));

    this.validateForm = this.fb.group({
      firstName: [{ value: null, disabled: true }, [Validators.required]],
      lastName: [{ value: null, disabled: true }, [Validators.required]],
      email: [{ value: null, disabled: true }, [Validators.email, Validators.required]],
      password: [null, [Validators.required, this.validatePassword]],
      checkPassword: [null, [Validators.required, this.confirmationValidator]]
    });

    this.route.queryParams
      .subscribe(async params => {
        this.seSvc.getSaleEventParameters(params);
        this.subs.add(this.seSvc.currentSaleEvent.subscribe((saleEvent: PRPVSaleEventFull | undefined) => {
          this.seId = saleEvent?.saleEventId ?? Guid.empty();
        }));

        this.registrationId = params.registerId;

        if (this.authSvc.isLoggedIn) {
          this.authSvc.logout();
        }

        this.validateForm.controls["firstName"].setValue(params.firstName);
        this.validateForm.controls["lastName"].setValue(params.lastName);
        this.validateForm.controls["email"].setValue(params.email);

        this.saleEventCode = params.saleEventCode;
        this.referralId = params.referralId;
        this.hasBooking = params.hasBooking == "1";
        //console.log("SaleEventCode: ", this.saleEventCode)
      });

    this.subs.add(this.policySvc.publicPolicies.subscribe(s => {
      this.policies = (s.filter(f => f.pageName?.strEq('register-light')))[0]?.policies ?? [];

      this.policies.forEach(policy => {
        this.validateForm.addControl(policy.policyId, new UntypedFormControl(false, policy.isMandatoryPolicy ? Validators.requiredTrue : null));
      });

      //console.log(this.validateForm)

    }));

    if (this.authSvc.isLoggedIn && !(this.editSvc.editModeOn.value && this.authSvc.isAdminUser())) {
      await this.router.navigate([this.tsvc.currentLanguage.value, 'event']);
    }
  }
}
