import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PurpleSelectComponent } from './purple-select.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { PurplePipeModule } from 'src/purple-widgets/custom-pipe/purple-pipe.module';
import { PurpleTranslationPipe } from 'src/purple-widgets/custom-pipe/translation.pipe';


@NgModule({
  declarations: [PurpleSelectComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzSelectModule,
    NzSpinModule,
    NzIconModule
    ,
    PurplePipeModule
  ],
  providers:[PurpleTranslationPipe],
  exports: [PurpleSelectComponent]
})
export class PurpleSelectModule { }
