import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactsComponent } from './contacts/contacts.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { SecurityModule } from 'src/core-modules/security/security.module';
import { PurpleDirectivesModule } from 'src/purple-widgets/custom-directives/purple-directives.module';
import { PurplePipeModule } from 'src/purple-widgets/custom-pipe/purple-pipe.module';
import { SaleEventsPreviewComponent } from './sale-events-preview/sale-events-preview.component';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { RouterModule } from '@angular/router';
import { LottieModule, LottieCacheModule } from 'ngx-lottie';
import player from 'lottie-web';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PurpleTranslationPipe } from 'src/purple-widgets/custom-pipe/translation.pipe';
import { NzNotificationModule } from 'ng-zorro-antd/notification';

export function playerFactory() {
  return player;
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NzButtonModule,
    NzCheckboxModule,
    NzFormModule,
    NzInputModule,
    NzSelectModule,
    ReactiveFormsModule,
    SecurityModule,
    NzLayoutModule,
    PurplePipeModule,
    NzDropDownModule,
    PurpleDirectivesModule,
    NzButtonModule,
    NzTabsModule,
    RouterModule,
    NzNotificationModule,
    LottieModule.forRoot({ player: playerFactory }),
    LottieCacheModule.forRoot()
  ],
  providers: [PurpleTranslationPipe],
  declarations: [ContactsComponent, SaleEventsPreviewComponent, TermsAndConditionsComponent]
})
export class PublicModule { }
