import { en_GB, en_US, fr_FR, it_IT, NZ_I18N } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BASE_PATH, Configuration } from 'src/core-modules/sdk';
import { IAuthenticatedUser } from 'src/core-modules/security/interfaces/iauthenticated-user';
import { StorageService } from 'src/core-modules/storage/storage.service';
import { PurpleLoaderModule } from 'src/purple-widgets/custom-components/purple-loader/purple-loader.module';
import { PurpleTranslationPipe } from 'src/purple-widgets/custom-pipe/translation.pipe';
import { PurplePipeModule } from 'src/purple-widgets/custom-pipe/purple-pipe.module';
import { PurpleDirectivesModule } from 'src/purple-widgets/custom-directives/purple-directives.module';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NZ_ICONS, NzIconModule } from 'ng-zorro-antd/icon';
import { AuthenticationModule } from './authentication/authentication.module';
import { PublicModule } from './public/public.module';
import { ErrorModule } from './error/error.module';
import { LayoutModule } from './layout/layout.module';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { SecurityModule } from 'src/core-modules/security/security.module';
import { ReferralModule } from './referral/referral.module';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import fr from '@angular/common/locales/fr';
import it from '@angular/common/locales/it';
import * as enGb from '@angular/common/locales/en-GB';
import * as enUs from '@angular/common/locales/en';
import { AuthenticationInterceptor } from 'src/core-modules/security/authentication.interceptor';
import { environment } from 'src/environments/default/environment';
import { EditUserAttributesModalComponent } from './private/modals/edit-user-attributes-modal/edit-user-attributes-modal.component';
import { CheckUserAttributesDrawerComponent } from './private/drawers/check-user-attributes-drawer/check-user-attributes-drawer.component';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { MailOutline, LockOutline, UserOutline, PlayCircleOutline, ManOutline, HomeOutline, CalendarOutline, FieldTimeOutline, ShoppingOutline, PhoneOutline, PauseCircleOutline, 
  InstagramOutline, FacebookOutline, QrcodeOutline, GlobalOutline, LoadingOutline, CloseOutline, WarningOutline, PrinterOutline, ShareAltOutline, ArrowRightOutline, ArrowLeftOutline, 
  PlusOutline, MinusOutline, DeleteOutline, WhatsAppOutline, CloseSquareOutline, TagOutline, RightOutline, SyncOutline, TeamOutline, InfoCircleOutline, EnvironmentOutline, BarcodeOutline,
   StarTwoTone, ArrowDownOutline, SaveOutline, TagTwoTone } from '@ant-design/icons-angular/icons';

registerLocaleData(it, "it-it");
registerLocaleData(it, "it");
registerLocaleData(fr, "fr-fr");
registerLocaleData(fr, "fr");
registerLocaleData(enUs.default, "en-us");
registerLocaleData(enGb.default, "en-gb");
 
const icons = [MailOutline, LockOutline, UserOutline, PlayCircleOutline, ManOutline, HomeOutline, CalendarOutline, FieldTimeOutline, ShoppingOutline, PhoneOutline,
PauseCircleOutline, InstagramOutline, FacebookOutline, QrcodeOutline, GlobalOutline, LoadingOutline, CloseOutline, WarningOutline, PrinterOutline, ShareAltOutline,
ArrowRightOutline, ArrowLeftOutline, PlusOutline, MinusOutline, DeleteOutline, WhatsAppOutline, CloseSquareOutline, TagOutline, RightOutline, SyncOutline, TeamOutline,
InfoCircleOutline, EnvironmentOutline, BarcodeOutline, StarTwoTone, ArrowDownOutline, SaveOutline, TagTwoTone];

@NgModule({
  declarations: [
    AppComponent,
    EditUserAttributesModalComponent, 
    CheckUserAttributesDrawerComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    PurpleLoaderModule.forRoot(),
    PurplePipeModule,
    PurpleDirectivesModule,
    NzMenuModule,
    NzLayoutModule,
    NzSelectModule,
    NzMenuModule,
    NzSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NzIconModule,
    NzDrawerModule,
    AuthenticationModule,
    PublicModule,
    LayoutModule,
    ErrorModule,
    NzButtonModule,
    SecurityModule,
    ReferralModule,
    NzNotificationModule
  ],
  exports:[
    
    EditUserAttributesModalComponent, 
    CheckUserAttributesDrawerComponent
  ],
  providers: [
    {
      provide: Configuration,
      useFactory: (storageSvc: StorageService) => new Configuration(
        {
          basePath: localStorage.getItem("BasePath") as string,
          accessToken: () => storageSvc.get<IAuthenticatedUser>("CurrentUser")?.token ?? undefined
        }
      ),
      deps: [StorageService],
      multi: false
    },
    { provide: NZ_I18N, useValue: it_IT },
    { provide: NZ_ICONS, useValue: icons },
    {
      provide: LOCALE_ID,
      deps: [StorageService],
      useFactory: (storageSvc: StorageService) => storageSvc.get<string>("Language") ?? localStorage.getItem("DefaultLanguage")
      //useValue: 'en-us'
    },
    {
      provide: NZ_I18N,
      useFactory: (localId: string) => {
        switch (localId) {
          case 'en-us':
            return en_US; 
          case 'en-gb':
            return en_GB;
          case 'it-it':
            return it_IT;
          case 'it':
            return it_IT;
          case 'fr-fr':
            return fr_FR;
          case 'fr':
            return fr_FR;
          default:
            return it_IT;
        }
      },
      deps: [LOCALE_ID]
    },
    PurpleTranslationPipe,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    }, 
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() { }

}
