import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { fadeAnimation } from 'src/core-modules/animations/fade-animation';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { AuthenticationService } from 'src/core-modules/security/authentication.service';
import { environment } from 'src/environments/default/environment';
import { PurpleLoaderService } from 'src/purple-widgets/custom-components/purple-loader/purple-loader.service';
import { EditService } from 'src/purple-widgets/custom-directives/editable-text/edit.service';
import { LayoutService } from './layout/layout.service';
import * as Sentry from '@sentry/angular';
import { Scope } from '@sentry/angular';
import { User as SentryUser } from '@sentry/angular';
import { StorageService } from 'src/core-modules/storage/storage.service';
import { PolicyAndUserAttributesService } from './policy-and-user-attributes-service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeAnimation]
})
export class AppComponent implements OnInit, OnDestroy {
  //HEADER
  
  constructor(public loaderSvc: PurpleLoaderService, public tsvc: AppTranslationService, public authService: AuthenticationService, private storageSvc: StorageService, private breakpointObserver: BreakpointObserver,
    public layoutSvc: LayoutService, public editSvc: EditService, private router: Router, private authenticationService: AuthenticationService, private policySvc: PolicyAndUserAttributesService) { }
    
    isMobile: boolean = false;
    subs: Subscription = new Subscription();
    isOnRefresh = false;
    showHeader = true;
    showFooter = true;
    fullContent = true;
    loaderPath: string = '/assets/loaders/'+environment.COMPANY_ABB+'/loader.json';
    loaderId: string = "general";
    isLanguageInit: boolean = false;
  
  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    this.layoutSvc.isUserMenuOpen = false;

    if (event instanceof NavigationStart) {
      this.loaderSvc.addRequest(this.loaderId);
    }
    if (event instanceof NavigationEnd) {
      this.loaderSvc.removeRequest(this.loaderId, environment.LOADER_DELAY);
    }

    if (event instanceof NavigationCancel) {
      this.loaderSvc.removeRequest(this.loaderId, environment.LOADER_DELAY);
    }
    if (event instanceof NavigationError) {
      this.loaderSvc.removeRequest(this.loaderId, environment.LOADER_DELAY);
    }
  }

  async ngOnInit(): Promise<void> {
    this.subs.add(this.tsvc.isInitialized.subscribe((init: boolean) => {
      this.isLanguageInit = init;
    }));

    this.subs.add(this.router.events.subscribe((e: any) => {
      this.navigationInterceptor(e);
    }));

    this.subs.add(
      this.layoutSvc.showHeader.subscribe((s: boolean) => {
        this.showHeader = s;
      })
    )

    this.subs.add(
      this.layoutSvc.showFooter.subscribe((s: boolean) => {
        this.showFooter = s;
      })
    )

    this.subs.add(
      this.layoutSvc.fullContent.subscribe((s: boolean) => {
        this.fullContent = s;
      })
    )

    var u: SentryUser = {
      email: this.authenticationService.currentUser?.user?.email ?? "Not logged user",
      id: this.authenticationService.currentUser?.user?.userId ?? "Not logged user",
    }

    Sentry.configureScope((scope: Scope) => {
      scope.setUser(u);
      scope.setTag("Customer", environment.COMPANY_NAME);
      scope.setTag("AngularType", "Booking");
    });

    this.subs.add(this.breakpointObserver
      .observe(['(max-width: 650px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      }));

    const editMode = this.storageSvc.get<boolean>("editModeActive") ?? false;
    this.editSvc.editModeOn.next(editMode)

    await this.tsvc.initialize();
    //await this.tsvc.getLanguages();
    await this.policySvc.getPublicPolicies();

    if (this.authService.isLoggedIn) {
      await this.policySvc.checkUserAttributesAndPolicies(this.isMobile)
    }

    /* this.tsvc.currentLanguage.subscribe(s => {
      this.loaderSvc.addRequest();
      setTimeout(() => {
        this.isOnRefresh = true;
        setTimeout(() => {
          this.isOnRefresh = false;
          this.loaderSvc.removeRequest();
        }, 10);
      }, 200);
    }); */
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
