interface Date {
  normalizeDatePurple(): Date;
  toIsoStringPurple(): string;
  isDstObserved(): boolean;
}

/**
* Return Date with seconds and milliseconds set to zero
* @returns {Date}
*/
Date.prototype.normalizeDatePurple = function (): Date {
  const date = this as Date;
  const newDate = new Date();

  newDate.setHours(date.getHours())
  newDate.setMinutes(date.getMinutes())
  newDate.setSeconds(0);
  newDate.setMilliseconds(0);

  return newDate;
}

Date.prototype.toIsoStringPurple = function (): string {
  const date = this as Date;

  var tzo = -date.getTimezoneOffset(),
    dif = tzo >= 0 ? '+' : '-',
    pad = function (num: number) {
      var norm = Math.floor(Math.abs(num));
      return (norm < 10 ? '0' : '') + norm;
    };

  return date.getFullYear() +
    '-' + pad(date.getMonth() + 1) +
    '-' + pad(date.getDate()) +
    'T' + pad(date.getHours()) +
    ':' + pad(date.getMinutes()) +
    ':' + pad(date.getSeconds()) +
    dif + pad(tzo / 60) +
    ':' + pad(tzo % 60);
}

Date.prototype.isDstObserved = function () {
  const date = this as Date;

  const january = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
  const july = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();

  
  return Math.max(january, july) !== date.getTimezoneOffset();
}