<div class="ps-w-100 ps-justify-content-center ps-d-flex">
  <div class="error-animation-container ps-d-flex ps-flex-wrap ps-justify-content-center">
    <div class="ps-w-100 ps-d-flex ps-flex-wrap ps-mb-2">
      <h1 editableText tranKey='404_error_tile' class="error-title ps-w-100">{{ '404_error_tile' | translation: 'Oops!': []}}</h1>
      <h3 editableText tranKey='404_error_subtile' class="error-subtitle ps-w-100">{{ '404_error_subtile' | translation: 'La pagina che hai cercato non esiste':
        []}}</h3>
      <div class="error-back-home ps-w-100">
        {{ 'error_404_back_to' | translation: 'Torna alla': []}}
        <a editableText tranKey='tran_key' [routerLink]="['/', tsvc.currentLanguage.value, 'login']">{{ 'error_page_homepage' | translation: 'HomePage': []}}</a>
      </div>
    </div>
    <div class="ps-w-100 ps-d-none ps-d-sm-flex ps-justify-content-center">

      <ng-lottie [options]="options" width="300px" height="auto"></ng-lottie>
    </div>
    <div class="ps-w-100 ps-d-flex ps-d-sm-none ps-justify-content-center">

      <ng-lottie [options]="options" width="210px" height="auto"></ng-lottie>
    </div>
  </div>

</div>
