import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { lastValueFrom } from 'rxjs';
import { LayoutService } from 'src/app/layout/layout.service';
import { SaleEventService } from 'src/app/sale-event-service.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BookingService, PurpleApiResponseStatus, SaleEventPreview } from 'src/core-modules/sdk';
import { AuthenticationService } from 'src/core-modules/security/authentication.service';
import { environment } from 'src/environments/default/environment';
import { EditService } from 'src/purple-widgets/custom-directives/editable-text/edit.service';
import { PurpleTranslationPipe } from 'src/purple-widgets/custom-pipe/translation.pipe';

@Component({
  selector: 'app-sale-events-preview',
  templateUrl: './sale-events-preview.component.html',
  styleUrls: ['./sale-events-preview.component.scss']
})
export class SaleEventsPreviewComponent implements OnInit {

  constructor(private route: ActivatedRoute, private seSvc: SaleEventService, public tsvc: AppTranslationService, private authSvc: AuthenticationService,
    private bookSvc: BookingService, private layoutSvc: LayoutService, private tranPipe: PurpleTranslationPipe, private router: Router, private editSvc:EditService) { }

  saleEvents: SaleEventPreview[] = [];
  subtitleText: string = this.tranPipe.transform("sale_events_preview_subtitle", "Ecco a te le prossime private sales<span class='dot'>.</span>")
  options: AnimationOptions = {
    path: '/assets/loaders/'+environment.COMPANY_ABB+'/no-private-sales.json',
  };

  ngOnDestroy(): void {
    this.layoutSvc.setFullContent(true);
  }

  async ngOnInit(): Promise<void> {
    this.layoutSvc.setFullContent(false);
    this.route.queryParams
      .subscribe(params => {
        this.seSvc.getSaleEventParameters(params)
      });

      if (this.authSvc.isLoggedIn && !this.authSvc.isAdminUser()) {
        await this.router.navigate([this.tsvc.currentLanguage.value,'event']);
      }

    const resp = await lastValueFrom(this.bookSvc.getNextPublicSaleEvents(this.tsvc.currentLanguage.value));

    if (resp.status == PurpleApiResponseStatus.Success) {
      this.saleEvents = resp.data!
    } else {
      this.saleEvents = []
    }
  }

}
