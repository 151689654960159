  <div class="login-external-container ps-w-100 ps-d-flex ps-flex-wrap">

    <div class="auth-form-mobile-banner ps-d-lg-none ps-d-flex ps-align-items-center" contentId="{{seId}}-authentication" contentTypeId="authentication-image-mobile"
    editableImage>
      <div class="auth-form-mobile-banner-opacity-layer ps-w-100 ps-p-0 ps-h-100 ps-d-flex ps-justify-content-start ps-align-items-center">

          <div class="ps-col-6 ps-pl-4 ps-pr-0">
            <h1 *ngIf="titleMobText!='#NS#'" class="auth-form-title ps-w-100" editableText tranKey='login_title_mob_new'>{{titleMobText}}</h1>
          </div>

      </div>

    </div>

    <div
      class="auth-form-left-container ps-d-flex ps-w-100 ps-flex-wrap ps-col-12 ps-col-lg-6 ps-align-items-center ps-justify-content-end">
      <div class="ps-d-flex ps-w-100 ps-flex-wrap">
        <h1 *ngIf="titleText!='#NS#'" editableText tranKey='login_title_desk_new' class="auth-form-title ps-mb-0 ps-w-100 ps-d-lg-block ps-d-none purple-title">
          {{titleText}}</h1>
        <h2 editableText tranKey='login_subtitle' [innerHtml]="subtitleText | safeHtml" class="auth-form-subtitle ps-w-100 ps-d-lg-block ps-d-none purple-subtitle"></h2>
        <!-- <p *ngIf="subSubtitleText!='#NS#'" editableText tranKey='login_log_with_saywow_account' class="ps-w-100 auth-form-login-with-saywow-account ps-mt-lg-2 ps-mt-0">
          {{subSubtitleText}}</p> -->
        <div class="ps-col-12 ps-px-0 ps-d-flex ps-align-items-start">


          <form nz-form [formGroup]="validateForm" class="login-form" (ngSubmit)="submitForm()">

            <nz-form-item>
              <nz-form-control nzErrorTip="Please input your email!">
                <nz-input-group nzPrefixIcon="mail">
                  <input editableText tranKey='login_form_email' type="email" nz-input formControlName="email" [placeholder]="'login_form_email' | translation: 'Email': []" />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-control nzErrorTip="Please input your Password!">
                <nz-input-group nzPrefixIcon="lock">
                  <input editableText tranKey='login_form_password' type="password" nz-input formControlName="password"
                    [placeholder]="'login_form_password' | translation: 'Password': []" />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
            <div class="ps-d-flex ps-flex-wrap ps-w-100 ps-align-items-center">
              <div class="ps-col-12 ps-col-lg-6 ps-px-0">
                <label nz-checkbox formControlName="remember">
                  <span editableText tranKey='login_remember'>{{ 'login_remember' | translation: 'Remember me': []}}</span>
                </label>
              </div>
              <div class="ps-col-12 ps-col-lg-6 ps-d-flex ps-justify-content-start ps-justify-content-lg-end ps-px-0">
                <a editableText tranKey='login_forgot_password' [routerLink]="['/', tsvc.currentLanguage.value, 'forgot-password']" class="login-form-forgot ps-mt-3">{{ 'login_forgot_password' | translation: 'Hai dimenticato la password?': []}}</a>
              </div>
            </div>
            <button editableText tranKey='login_login' nz-button class="submit-button ps-mt-4 purple-button" nzType="primary">{{ 'login_login' | translation: 'Accedi': []}}</button>
            <div *ngIf="!isKering" class="ps-mt-3 ps-w-100 ps-d-flex ps-align-items-center ps-mb-4">
              <p editableText tranKey='login_have_an_account' class="ps-mb-0 ps-pr-3 fsp-16-0">{{ 'login_have_an_account' | translation: 'Non hai un account?':
                []}}</p>
              <button editableText tranKey='login_register_now' class="sw-border-radius" [routerLink]="['/',tsvc.currentLanguage.value, 'register']" nz-button nzType="default">{{
                'login_register_now' | translation: 'Registrati': []}}</button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div
      class="auth-form-right-container ps-col-6 ps-d-none ps-d-lg-flex ps-justify-content-center ps-align-items-end ps-justify-content-start">
      <div class="auth-form-vertical-image" contentId="{{seId}}-authentication" contentTypeId="authentication-image"
        editableImage>
      </div>
    </div>

  </div>

