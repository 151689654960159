import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LottieModule,LottieCacheModule } from 'ngx-lottie';
import player from 'lottie-web';
import { Error404Component } from './404/404.component';
import { PurpleTranslationPipe } from 'src/purple-widgets/custom-pipe/translation.pipe';
import { PurplePipeModule } from 'src/purple-widgets/custom-pipe/purple-pipe.module';
import { RouterModule } from '@angular/router';
import { SafeHtmlPipe } from 'src/purple-widgets/custom-pipe/safe-html.pipe';

export function playerFactory() {
  return player;
}

@NgModule({
  imports: [
    CommonModule,
    LottieModule.forRoot({ player: playerFactory }),
    LottieCacheModule.forRoot(),
    PurplePipeModule,
    RouterModule
  ],
  providers:[PurpleTranslationPipe, SafeHtmlPipe],
  declarations: [Error404Component]
})
export class ErrorModule { }
