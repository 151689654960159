<nz-select nzPlaceHolder="{{placeholder}}" nzShowSearch nzServerSearch [nzDisabled]="disabled" class="{{cssClass}}"
  [nzAllowClear]="showClear" [nzShowSearch]="showSearch" [nzShowArrow]="showArrow" [(ngModel)]="currentItem"
  (nzScrollToBottom)="getItems()" (nzOnSearch)="searchItems($event)" (ngModelChange)="setValue($event)"
  [nzDropdownRender]="scrollTemplate" >

  <ng-container *ngFor="let i of items">
    <nz-option [nzDisabled]="isOptionDisabled(i.id!)" *ngIf="!isLoading" [nzValue]="i.id!" [nzLabel]="i.name!"></nz-option>
  </ng-container>
  <nz-option *ngIf="isLoading" nzDisabled nzCustomContent>
    <div class="ps-d-flex ps-flex-wrap ps-align-items-center ps-w-100">
      <nz-spin nzSimple [nzIndicator]="indicatorTemplate"></nz-spin>
      <p class="ps-mb-0 ps-ml-2" editableText tranKey='loading_data'>{{"loading_data" | translation:"Caricamento dati"}}...</p>
    </div>
  </nz-option>
</nz-select>

<ng-template #indicatorTemplate>
  <i class="fsp-22-0" nz-icon nzType="loading"></i>
</ng-template>

<ng-template #scrollTemplate>
  <div *ngIf="isLoadingScroll" class="ps-d-flex ps-flex-wrap ps-justify-content-center ps-align-items-center ps-w-100">
    <nz-spin nzSimple [nzIndicator]="indicatorTemplate"></nz-spin>
  </div>
</ng-template>