import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { ApiSelectFilter, ApiPurpleSelectItem } from 'src/core-modules/sdk';
import { PurpleLoaderService } from '../purple-loader/purple-loader.service';

@Component({
  selector: 'app-purple-select',
  templateUrl: './purple-select.component.html',
  styleUrls: ['./purple-select.component.scss']
})
export class PurpleSelectComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() debounceTime: number = 500;
  @Input() scrollFunction: ((args: any) => any) | undefined;
  @Input() searchNameFunction: ((args: any) => any) | undefined;
  @Input() searchIdFunction: ((args: any) => any) | undefined;
  @Input() currentItem: any;
  @Input() placeholder: string | undefined;
  @Input() cssClass: string = "purple-select-component";
  @Input() showClear: boolean = true;
  @Input() showSearch: boolean = true;
  @Input() showArrow: boolean = true;
  @Input() disabled: boolean = false;
  @Input() refreshOptions: number = 0;
  @Input() searchFilters: ApiSelectFilter[] = [];
  @Input() disbledOptions: string[]= [];
  @Output() onValueChange = new EventEmitter<string>();

  isLoading: boolean = true;
  isLoadingScroll: boolean = false;
  currentPage = 0;
  currentSearchPage = 0;
  canLoadMore = true;
  canLoadMoreSearch = true;
  items: Array<ApiPurpleSelectItem> = [];
  filterTextChange: Subject<string> = new Subject<string>();
  currentSearch: string | undefined;
  subs: Subscription = new Subscription();

  constructor(public loaderSvc: PurpleLoaderService, public tsvc: AppTranslationService) { }
  
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  async ngAfterViewInit(): Promise<void> {
    //console.log("AFTER VIEW INIT - PURPLE SELECT: ", this.currentItem)
    if (this.currentItem != undefined) {
      //console.log("AFTER VIEW INIT - PURPLE SELECT: ", this.currentItem)
      await this.searchsInit(this.currentItem);
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    //console.log("Refresh: ", changes['refreshOptions'])
    const currentValue = changes['refreshOptions']?.currentValue??undefined;
    const previousValue = changes['refreshOptions']?.previousValue??undefined;

    if (currentValue != null && currentValue != undefined &&
      previousValue != null && previousValue != undefined &&
      currentValue != previousValue) {
        //console.log("Refresh")
        this.canLoadMore = true;
        this.currentPage = 0;
        this.items = [];
        await this.getItems();
        if (this.currentItem != undefined) {
        await this.searchsInit(this.currentItem);
      }
    }

    if (this.currentItem != undefined && this.items.length == 0) {
      await this.searchsInit(this.currentItem);
    }
  }

  isOptionDisabled(id:string){
    //console.log("Id: ", id)
    return this.disbledOptions.findIndex(f=> f == id) != -1;
  }

  async ngOnInit(): Promise<void> {
    //console.log("disbledOptions: ", this.disbledOptions)
    //console\.log\(([^)]+)\)
    //console.log("ON INIT - PURPLE SELECT: ", this.currentItem)
    /* await this.getItems();
    if (this.currentItem != undefined) {
      await this.searchsInit(this.currentItem);
    } */
  }

  async getItems() {
    //console.log("GETITEMS: ", )
    if (this.currentSearch != undefined && this.currentSearch != "") {
      if (this.canLoadMoreSearch && this.searchNameFunction != undefined) {
        this.currentSearchPage += 1;
        this.isLoadingScroll = true;
        var res = (await this.searchNameFunction({ searchName: this.currentSearch, pageNumber: this.currentSearchPage, pageSize: 20, culture: this.tsvc.currentLanguage.value, searchFilters: this.searchFilters }).toPromise()).data;
        this.canLoadMoreSearch = res.canLoadMore ?? false;
        this.items = [...this.items, ...res.data!]
        this.currentPage = 0;
        this.isLoadingScroll = false;
        //console.log("Items: ", this.items)
      }
    } else {
      if (this.canLoadMore && this.scrollFunction != undefined) {
        this.currentPage += 1;
        this.isLoadingScroll = true;
        //console.log(this.scrollFunction)
        var res = (await this.scrollFunction({ pageNumber: this.currentPage, pageSize: 20, culture: this.tsvc.currentLanguage.value, searchFilters: this.searchFilters }).toPromise()).data;
        this.canLoadMore = res.canLoadMore ?? false;
        this.items = [...this.items, ...res.data!]
        this.currentSearchPage = 0;
        this.isLoadingScroll = false;
        //console.log("Items: ", this.items)
      }
    }

  }

  async searchItems(value: string, pageNumber: number = 1): Promise<void> {
    this.currentSearch = value;
    if (this.searchNameFunction != undefined) {
      this.isLoading = true;
      if (!this.filterTextChange.observed) {
        this.subs.add(this.filterTextChange
          .pipe(debounceTime(this.debounceTime), distinctUntilChanged())
          .subscribe(async filterQuery => {

            //console\.log\(([^)]+)\)
            //console\.log\(([^)]+)\).toPromise())
            var res: any;
            //console.log("SEARCH VALUE: ", filterQuery)
            //console.log("SEARCH: ", this.searchFilters)
            if (filterQuery == "" && this.scrollFunction != undefined) {
              res = (await this.scrollFunction({ pageNumber: 1, pageSize: 20, culture: this.tsvc.currentLanguage.value, searchFilters: this.searchFilters }).toPromise()).data
              this.canLoadMore = res.canLoadMore;
            }
            else if (this.searchNameFunction != undefined) {
              res = (await this.searchNameFunction({ searchName: filterQuery, pageNumber: pageNumber, pageSize: 20, culture: this.tsvc.currentLanguage.value, searchFilters: this.searchFilters }).toPromise()).data;
              //console.log("ENTRO IN SEARCH: ", res)
            this.canLoadMoreSearch = res.canLoadMore;
            }
            //console.log("RES FUORI: ", res)
            this.items = res.data!;
            this.currentPage = 0;
            this.isLoading = false;
          }));
      }else{
        this.canLoadMore = true;
      }
      this.filterTextChange.next(value);

      //console\.log\(([^)]+)\):\nValue: ", this.currentItem, "\nItems: ", this.items)
    }
  }

  async searchsInit(value: string): Promise<void> {
    //console.log("SEARCH INIT")
    if (this.searchIdFunction != undefined) {
      this.isLoading = true;
      var res = (await this.searchIdFunction({ searchName: value, pageNumber: 1, pageSize: 5, culture: this.tsvc.currentLanguage.value, searchFilters: this.searchFilters }).toPromise()).data;
      if (res != undefined && res.data.length > 0) {
        if (this.items.findIndex(f => f.id == res.data![0].id) == -1) {
          this.items = [...this.items, ...res.data!]
        }
        this.currentItem = res.data![0].id
      }
      //console.log("nValue: ", this.currentItem, "\nItems: ", this.items)
      this.isLoading = false;
    }
  }

  setValue(event: string) {
    //console\.log\(([^)]+)\)
    this.onValueChange.emit(this.currentItem);
  }

}
