import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { LottiePlayer } from 'ngx-lottie/lib/symbols';
import { PurpleLoaderService } from './purple-loader.service';

@Component({
  selector: 'purple-loader',
  templateUrl: './purple-loader.component.html',
  styleUrls: ['./purple-loader.component.scss']
})
export class PurpleLoaderComponent implements OnInit, OnChanges {
  @Input() loaderPath!: string;
  @Input() loaderId!: string;
  @Input() width: string = 'auto';
  @Input() height: string = 'auto';
  @Input() isGlobal: boolean = true;
  @Input() zIndex: number = 9999999;
  @Input() speed: number = 1;
  @Input() loop: boolean = true;
  @Input() autoplay: boolean = true;
  @Input() play: boolean | undefined;
  @Input() backgroundColor: string = '#ffffff';
  
  private anim: LottiePlayer | undefined;
  isShowing: boolean = true;
  constructor(private loaderSvc: PurpleLoaderService) {}

  options: AnimationOptions = {};
  ngOnInit(): void {
    if (!this.loaderPath) {
      throw new TypeError("'loaderPath' is required");
    }

    if (!this.autoplay && this.play == undefined) {
      throw new Error("Parametro play richiesto se la modalità autoplay è disattivata");
    }

    this.options = {
      path: this.loaderPath,
      loop: this.loop,
      autoplay: this.autoplay
    }

    if (this.anim != undefined) {
      if (this.isShowing) {
        if (!this.autoplay && this.play || this.autoplay) {
          this.anim.play();
        }
        if (!this.autoplay && !this.play || this.autoplay) {
          this.anim.stop();
        }
      } else {
        this.anim.stop();
      }
    }

    this.loaderSvc.reqs.subscribe(async (reqs: string[]) => {
      var idx = reqs.indexOf(this.loaderId);
      if(idx != -1){
        this.isShowing = true;
      }else{
        this.isShowing = false;
      }
    });
  }

  ngOnChanges(): void {
    this.options = {
      path: this.loaderPath,
      loop: this.loop,
      autoplay: this.autoplay
    }

    if (this.anim != undefined) {
      if (this.isShowing) {
        if (!this.autoplay && this.play || this.autoplay) {
          this.anim.play();
        }
        if (!this.autoplay && !this.play || this.autoplay) {
          this.anim.stop();
        }
      } else {
        this.anim.stop();
      }
    }

  }

  handleAnimation(anim: any) {
    this.anim = anim;

    if (this.anim != undefined) {
      this.anim.setSpeed(this.speed);
    }
  }
}
