<nz-layout *ngIf="isLanguageInit" class="layout-container">

  <button *ngIf="authService.isAdminUser()" (click)="editSvc.toggleEditMode()" class="purple-edit-button" nz-button
    nzType="primary">
    <span class="desktop-edit-button">Edit: {{editSvc.editModeOn.value?'active':'disable'}}</span>
    <span class="mobile-edit-button" nz-icon [nzType]="editSvc.editModeOn.value?'pause-circle':'play-circle'"
      nzTheme="outline"></span>
  </button>

  <app-public-header *ngIf="showHeader && !authService.isLoggedIn"></app-public-header>
  <app-private-header *ngIf="showHeader && authService.isLoggedIn"></app-private-header>
  <nz-content class="layout-content-container {{showHeader?'':'no-margin'}} {{fullContent?'':'no-full'}}">
    <router-outlet></router-outlet>
  </nz-content>
  <app-footer *ngIf="showFooter"></app-footer>
</nz-layout>
<!-- 
<app-loader *ngIf="loaderSvc.isLoading() || !(tsvc.isInitialized | async)"></app-loader>  -->

<purple-loader height="500px" [loaderPath]="loaderPath" [loaderId]="loaderId"></purple-loader>