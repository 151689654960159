import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { SaleEventService } from 'src/app/sale-event-service.service';
import 'src/purple-widgets/helpers/date.extensions';

@Pipe({
  name: 'saleEventDate',
})
export class SaleEventDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe, private seSvc: SaleEventService) {  }

  transform(value: string | null | undefined, format?: string, timezone?: string, locale?: string): string | null{

    if (timezone == undefined || timezone == null) {
      timezone = this.seSvc.currentSaleEventUtc.value?.utcOffsetString ?? undefined;
    }

    var currentDate: Date | undefined | null = undefined;

    if (value != undefined && value != null) {
      var gmt = value.substring(value.length - 6)

      currentDate = new Date(Date.parse(value));
      const nowDate = new Date().toIsoStringPurple();
      const nowDateFinal = nowDate.substring(0, nowDate.length - 6) + gmt;

      var isDstPsDate = currentDate.isDstObserved();
      var isDstDNow = new Date(Date.parse(nowDateFinal)).isDstObserved();

      if (isDstDNow && !isDstPsDate) {
        currentDate.setMinutes(currentDate.getMinutes() - 60);
      }
      else if (!isDstDNow && isDstPsDate) {
        currentDate.setMinutes(currentDate.getMinutes() + 60);
      }
    }

    return this.datePipe.transform(currentDate, format, timezone, locale);
  }
}