import { Pipe, PipeTransform } from '@angular/core';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { environment } from 'src/environments/default/environment';

@Pipe({
  name: 'translation'
})
export class PurpleTranslationPipe implements PipeTransform {

  constructor(private tsvc: AppTranslationService) {

  }

  private defaultValue: string = "To Translate"

  transform(value: any, defaultValue: string = this.defaultValue, replacers: string[] = []): string {

    var str : string;

    try {

      str = (this.tsvc.translations as any)[this.tsvc.currentLanguage.value][environment.EDIT_TRAN_AFFIX+value];

      if(str == undefined || str == null){
        throw new Error("Default");
      }

      /* if(str != defaultValue && defaultValue != this.defaultValue){
        this.tsvc.newTranslations.set(environment.EDIT_TRAN_AFFIX+value, defaultValue)

        str = environment.EDIT_TRAN_SYMBOL + defaultValue + environment.EDIT_TRAN_SYMBOL
      } */

      replacers.forEach((replacer, idx) => {
        str = str.replace('{'+idx+'}', replacer)
      });

      //console.log("LAN: ", this.tsvc.currentLanguage.value, "KEY: ", environment.EDIT_TRAN_AFFIX+value, " - STR: ", str)
      return str;

    } catch (error) {
      this.tsvc.newTranslations.set(environment.EDIT_TRAN_AFFIX+value, defaultValue)
      var str = environment.NEW_TRAN_SYMBOL + defaultValue + environment.NEW_TRAN_SYMBOL;
      //var str = defaultValue;

      replacers.forEach((replacer, idx) => {
        str = str.replace('{'+idx+'}', replacer)
      });

      
      return str;
    }

  }

}
