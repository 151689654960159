import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { PurpleTranslationPipe } from './translation.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { CrcPipe } from './crc.pipe';
import { GenderPipe } from './gender.pipe';
import { PurpleDatePipe } from './date.pipe';
import { SaleEventDatePipe } from './sale-event-date.pipe.';
 
@NgModule({
  imports: [
    CommonModule
  ],
  providers: [DatePipe],
  declarations: [PurpleTranslationPipe, SafeHtmlPipe, CrcPipe, GenderPipe, PurpleDatePipe, SaleEventDatePipe],
  exports: [PurpleTranslationPipe, SafeHtmlPipe, CrcPipe, GenderPipe, PurpleDatePipe, SaleEventDatePipe]
})
export class PurplePipeModule { }
