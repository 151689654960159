interface String {
    strEq(arg: string): boolean;
    isNullOrEmpty(arg: string | null | undefined): boolean;
  }
  
  /**
 * Return if two strings are equal (invariant case)
 * @param {string} arg String must be equale
 * @returns {boolean}
 */
  String.prototype.strEq = function (arg: string): boolean {
    var s = this as string;
    if (String.prototype.isNullOrEmpty(s) && String.prototype.isNullOrEmpty(arg))
        {
            return true;
        }
        if (String.prototype.isNullOrEmpty(s) || String.prototype.isNullOrEmpty(arg))
        {
            return false;
        }

        return s.trim().toLowerCase() === arg.trim().toLowerCase();
  };

  String.prototype.isNullOrEmpty = function (arg: string | null | undefined): boolean {
    return ((arg == null || arg == undefined) || 0 == arg.length) ? true : false;
  };
